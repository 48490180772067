import { Button, Checkbox, Divider, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSortDown } from "@fortawesome/free-solid-svg-icons";
import ActivityCard from "./item/ActivityCard";
import store from '../lib/store'
import { setModalCreateActivity } from "../reducers/modals";
import { Activity } from "../schemas/activity";
import moment from 'moment'
import { setFilterActivityFoodTypes } from "../reducers/activity";
import { DividerBadges, DividerFloatRight, EmptyList } from "../styles/layout";
import styled from 'styled-components'
import { CSSTransition, TransitionGroup } from "react-transition-group";
import SearchableSelect from "./lib/SearchableSelect";

const ActivityList = () => {
    const activities = useSelector((state: any) => state.activities)
    const locations = useSelector((state: any) => state.locations)

    const getDateLabel = (time : string) => {
        const dateNow = moment(moment().format("yyyy-MM-DD"))
        const date = moment(time)

        const diff = date.diff(dateNow, "days");

        if(diff === 0)
            return "Today"
        else if(diff === 1)
            return "Tomorrow"
        else if(diff < 7)
            return date.format('dddd')
        else if(diff >= 7 && diff < 14)
            return "1 week"
        else
            return "Future"
    }

    const getSortedAndGrouped = () => {
        const new_list : any = []

        // sort list first
        let sorted_activities = activities.data.sort((a : Activity, b : Activity) => {
            return a.time.localeCompare(b.time)
        })

        // Filter on foodtypes
        sorted_activities = sorted_activities.filter((val : Activity) => {
            return val.location.foodTypes.some(f => activities.filters.food_types.includes(f))
        })
        
        // Divide into groups
        sorted_activities.forEach((val : Activity) => {
            const date = getDateLabel(val.time.split(" ")[0])

            const index = new_list.findIndex((v : any) => v.date === date)

            // Exists, update list
            if(index > -1)
                new_list[index].data.push(val)
            else{
                new_list.push({
                    date: date,
                    data: [val]
                })
            }
        })

        return new_list
    }

    return (
        <>
            <Grid container flexDirection={"column"} height={"100%"}>
                <Grid item xs="auto">
                    <Grid container spacing={1} columns={3}>
                        <Grid item xs={3}>
                            <Divider textAlign="left"> 
                                <Button
                                    sx={{color: "white"}}
                                >
                                    Filters <FontAwesomeIcon style={{transform: (!false) ? "rotate(-90deg)" : "", marginLeft: 6}} icon={faSortDown} />
                                </Button>
                            </Divider>
                        </Grid>

                        <Grid item xs={1}>
                            <SearchableSelect 
                                label="Food"
                                items={locations.food_types} 
                                selectedItems={activities.filters.food_types} 
                                onItemChange={(items) => store.dispatch(setFilterActivityFoodTypes(items))} 
                                checkAll 
                                placeholder="Search foodtype..."
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Divider textAlign="left" sx={{position: "relative", mb: "15px", mt: 1, textTransform: "uppercase"}}>
                                Lunch trains 

                                <DividerFloatRight>
                                    <Button 
                                        variant="contained" 
                                        size="small" 
                                        sx={{color: "white"}}
                                        onClick={() => store.dispatch(setModalCreateActivity(true))}
                                        startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                                    >
                                        Create
                                    </Button>
                                </DividerFloatRight>
                            </Divider>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs style={{ overflowY: "auto" }}>
                    <TransitionGroup>
                    {
                        getSortedAndGrouped().length === 0
                        ? (
                            <CSSTransition
                            timeout={400}
                            classNames="fade"
                            key={`location-empty`}
                            >
                                <EmptyList>
                                    It seems like there are no upcoming lunches.<br/>
                                    Create a lunchtrain now and connect with people.
                                </EmptyList>
                            </CSSTransition>)
                        : (
                            getSortedAndGrouped().map((val : any) => (
                                <CSSTransition
                                    timeout={400}
                                    classNames="fade"
                                    key={`location-${val.date}`}
                                >
                                    <>
                                        <DividerBadges>
                                            {val.date}
                                        </DividerBadges>

                                        <TransitionGroup>
                                            {
                                                val.data.map((activity: Activity) => (
                                                    
                                                    <CSSTransition
                                                        timeout={400}
                                                        classNames="fade"
                                                        key={`activity-card-transition-${activity.eventID}`}
                                                    >
                                                        <ActivityCard
                                                            key={`activity-card-${activity.eventID}`}
                                                            item={activity}
                                                        />
                                                    </CSSTransition>
                                                ))
                                            }
                                        </TransitionGroup>
                                    </>
                                </CSSTransition>
                            ))
                        )
                    }
                    </TransitionGroup>
                </Grid>
            </Grid>
        </>
    )
}

export default ActivityList;

