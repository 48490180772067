import { Slider, TextField, Typography, Rating } from '@mui/material';
import styled from 'styled-components';


export const DistanceTag = styled.div`
display: inline-block;
font-size: 14px;
background: #e1e1e1;
padding: 5px;
border-radius: 5px;

&:not(:first-of-type){
    margin-left: 5px;
}
`;

export const DividerBadges = styled.div`
  text-align: center;
  display: table;
  margin: auto;
  background: #373737;
  font-size: 12px;
  padding: 10px;
  color: white;
  margin-top: 25px;
  border-radius: 15px;
  font-weight: 700;

  &:first-of-type{
      margin-top: 0;
  }
`

export const EmptyList = styled.div`
    text-align: center;
    color: white;
    font-size: 14px;
    margin-top: 15px;
`

export const RatingLabel = styled(Typography)`
    color: #7573B6;
    font-size: 12px;
`

export const TextAreaSmall = styled(TextField)`
  & *{
      font-size: 14px;
  }
`;

export const ModalBody = styled.div`
  padding: 25px;
`;

export const DividerFloatRight = styled.div`
    position: absolute;
    padding-left: 10px;
    top: -7px;
    right: 0;
    z-index: 2;
    background: #4d4d4d;
`

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

export const RatingSlider = styled(Slider)(({ theme }) => ({
    height: 2,
    paddingTop: 10,
    paddingBottom: 10,
    '& .MuiSlider-thumb': {
      height: 22,
      width: 22,
      backgroundColor: '#fff',
      boxShadow: iOSBoxShadow,
      '&:focus, &:hover, &.Mui-active': {
        boxShadow:
          '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: iOSBoxShadow,
        },
      },
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 10,
      fontWeight: 'normal',
      top: 26,
      backgroundColor: 'unset',
      color: theme.palette.text.primary,
      '&:before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent'
      },
    },
    '& .MuiSlider-rail': {
      opacity: 0.5,
      backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#bfbfbf',
      height: 8,
      width: 8,
      borderRadius: 4,
      '&.MuiSlider-markActive': {
        opacity: 1,
        backgroundColor: 'currentColor',
      },
    },
  }));

  export const HeartRating = styled(Rating)({
    // 'display': 'inline-block',
    // 'font-weight': 900,
    // 'font-family': 'Font Awesome 6 Free',
    '& .MuiRating-iconFilled': {
      color: '#7573b6'
    }
  }) 
  ;
