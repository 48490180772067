import {Activity} from '../schemas/activity';
import { User } from '../schemas/user';

//Schema
interface stateInterface {
    data: Activity[],
    filters: {
        food_types: string[];
    }
}

const initialState: stateInterface = {
    data: [],
    filters: {
        food_types: []
    }
}

const SET_ACTIVITIES = "SET_ACTIVITIES";

// Filters
const SET_FILTER_ACTIVITY_FOOD_TYPES = "SET_FILTER_ACTIVITY_FOOD_TYPES";

const ADD_ACTIVITY = "ADD_ACTIVITY"
const EDIT_ACTIVITY = "EDIT_ACTIVITY"
const REMOVE_ACTIVITY = "REMOVE_ACTIVITY"

const JOIN_ACTIVITY = "JOIN_ACTIVITY"
const LEAVE_ACTIVITY = "LEAVE_ACTIVITY"

export const setActivities = (payload : Activity[]) => {
    return { type: SET_ACTIVITIES, payload};
}

export const addActivity = (payload : Activity) => {
    return { type: ADD_ACTIVITY, payload};
}

export const editActivity = (payload : {data: Activity, id: number}) => {
    return { type: EDIT_ACTIVITY, payload};
}

export const removeActivity = (payload : number) => {
    return { type: REMOVE_ACTIVITY, payload};
}

export const setFilterActivityFoodTypes = (payload : string[]) => {
    return { type: SET_FILTER_ACTIVITY_FOOD_TYPES, payload};
}

export const joinActivity = (payload : {user: User, id: number}) => {
    return { type: JOIN_ACTIVITY, payload};
}

export const leaveActivity = (payload : {user_id: number, id: number}) => {
    return { type: LEAVE_ACTIVITY, payload};
}

export const activitiesReducer = (state = initialState, action : any) => {
    if(action.type === SET_ACTIVITIES){
        // Set new list
        return Object.assign({}, state, {
            data: action.payload
        });
    }
    else if(action.type === ADD_ACTIVITY){
        const new_data = state.data;

        // Skip adding duplicates
        if(new_data.findIndex((loc : Activity) => loc.eventID == action.payload.eventID) === -1)
            new_data.push(action.payload)

        // Set new list
        return Object.assign({}, state, {
            data: new_data
        });
    }
    else if(action.type === EDIT_ACTIVITY){
        const new_data = state.data;
        const index = new_data.findIndex((loc : Activity) => loc.eventID == action.payload.id)
        new_data[index] = {...new_data[index], ...action.payload.data}

        // Set new list
        return Object.assign({}, state, {
            data: new_data
        });
    }
    else if(action.type === REMOVE_ACTIVITY){
        const new_data = state.data.filter((loc: Activity) => loc.eventID != action.payload);

        // Set new list
        return Object.assign({}, state, {
            data: new_data
        });
    }


    else if(action.type === JOIN_ACTIVITY){
        const new_data = state.data;
        const index = new_data.findIndex((loc : Activity) => loc.eventID == action.payload.id)

        // add if already not in list
        if(new_data[index].participants.findIndex((val : User) => val.id == action.payload.user.id) === -1)
            new_data[index].participants.push(action.payload.user)

        // Set new list
        return Object.assign({}, state, {
            data: new_data
        });
    }
    else if(action.type === LEAVE_ACTIVITY){
        const new_data = state.data;
        const index = new_data.findIndex((loc : Activity) => loc.eventID == action.payload.id)
        new_data[index].participants = new_data[index].participants.filter((val : User) => val.id != action.payload.user_id)

        // Set new list
        return Object.assign({}, state, {
            data: new_data
        });
    }

    else if(action.type === SET_FILTER_ACTIVITY_FOOD_TYPES){
        // Set new list
        return Object.assign({}, state, {
            filters: {...state.filters, food_types: action.payload} 
        });
    }

    return state;
};

