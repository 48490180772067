import { Grid, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { ChangeEvent, SyntheticEvent, useState, useEffect } from "react";

import styled from 'styled-components';

interface PropsInterface {
    items: String [];
    changeType: (selected: String[]) => void;
    // onChange: (value: any) => void;
}

const TypeButtons = ({ items, changeType }: PropsInterface) => {
    const [selectedItems, setSelected] = useState([... items]);
    const [allSelected, setAll] = useState(true);

    // Callback trigger after selected types are updated
    useEffect(()=> {
        changeType(selectedItems);
    }, [selectedItems])

    const handleChange = (type: String) => {
        if (type == 'All') {
            if (allSelected) {
                setSelected([]);
                setAll(false);
            }
            else {
                setSelected(items);
                setAll(true)
            }
        }
        else {
            // Unchecking type
            if (selectedItems.indexOf(type) !== -1 ) {
                // Savig copy of array
                var tmp = [...selectedItems]
                const index = tmp.indexOf(type);
                tmp.splice(index,1);
                setSelected(tmp);
            }
            // Checking type
            else {
                setSelected([...selectedItems,type]);
            }
        }        
    }
    return (
        <Grid container>
            <FormGroup sx={{ display: 'inline' }}>
                {/* Check all options */}
                <FormControlLabel
                    control={<Checkbox size="small" checked={allSelected}></Checkbox>}
                    label={'All'}
                    labelPlacement='top'
                    onChange={() => {handleChange('All')}}
                    value={'All'}
                ></FormControlLabel>
                {/* List of types */}
                {items.map((type) => {
                    return <FormControlLabel
                        control={<Checkbox size="small"></Checkbox>}
                        label={type}
                        labelPlacement='top'
                        onChange={() => {handleChange(type)}}
                        value={type}
                        checked = {selectedItems.includes(type)}
                    ></FormControlLabel>
                })}
            </FormGroup>
        </Grid>
    )
}

export default TypeButtons;
