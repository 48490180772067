export const breakpoint = {
    xs: 0,
    sm: 600-1,
    md: 900-1,
    lg: 1200-1,
    xl: 1536-1,
}

export const device = {
    xs: `(min-width: ${breakpoint.xs}px) and (max-width: ${breakpoint.sm}px)`,
    sm_down: `(min-width: ${breakpoint.xs}px) and (max-width: ${breakpoint.md}px)`,
    sm: `(min-width: ${breakpoint.sm}px) and (max-width: ${breakpoint.md}px)`,
    md_down: `(min-width: ${breakpoint.xs}px) and (max-width: ${breakpoint.lg}px)`,
    md: `(min-width: ${breakpoint.md}px) and (max-width: ${breakpoint.lg}px)`,
    lg_down: `(min-width: ${breakpoint.xs}px) and (max-width: ${breakpoint.xl}px)`,
    lg: `(min-width: ${breakpoint.lg}px) and (max-width: ${breakpoint.xl}px)`,
    xl: `(min-width: ${breakpoint.xl}px)`,
};