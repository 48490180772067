import { Grid } from "@mui/material";
import Rating from "./Rating";
import styled from 'styled-components'
import { Review } from "../../schemas/review";

interface PropsInterface{
    review: Review;
}

const ReviewItem = ({review} : PropsInterface) => {

    return (
        <Holder>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Title>{review.user.name}</Title>
                    <Rating rating={review.rating} dark />
                </Grid>
                <Grid item xs="auto">
                    <Image src={review.user.avatar} />
                </Grid>
            </Grid>

            <Text>
                {review.comment}
            </Text>

        </Holder>
    )
}

export default ReviewItem;


const Title = styled.h3`
    padding: 0;
    margin: 0;
    font-size: 16px;
`;

const Text = styled.p`
    font-size: 12px;
`;

const Image = styled.div<{src: string}>`
    background-image: url(${({src}) => src});
    background-size: cover;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    display: block;
`;

const Holder = styled.div`
    padding-bottom: 20px;

    &:not(:last-of-type){
        border-bottom: 1px solid #828282;
        margin-bottom: 20px;
    }
`;