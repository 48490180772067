import styled from 'styled-components';

const SignInSlack = () => {
    return (
        <>
            <SlackButton href={`https://slack.com/oauth/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=${process.env.REACT_APP_SLACK_SCOPE}&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI}&team=${process.env.REACT_APP_SLACK_TEAM_ID}`}>
                <SVGIcon src={process.env.PUBLIC_URL + "/imgs/slack.svg"} /> Sign in with Slack
            </SlackButton>
        </>
    )
}

export default SignInSlack;

const SVGIcon = styled.img`
    height: 20px;
    width: 20px;
    margin-right: 12px;
`;

const SlackButton = styled.a`
    align-items:center;
    color:#000;
    background-color:#fff;
    border:1px solid #ddd;
    border-radius:4px;
    display:inline-flex;
    font-family:Lato, sans-serif;
    font-size:16px;
    font-weight:600;
    height:48px;
    justify-content:center;
    text-decoration:none;
    width:256px;
`; 