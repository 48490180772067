import React, { useEffect, useRef, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, Polyline} from '@react-google-maps/api';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { Location } from "../schemas/locations";
import store from '../lib/store';
import { setInBound, setSelected } from '../reducers/locations';
import styled from 'styled-components';
import moment from 'moment'
import { breakpoint } from '../styles/breakpoints';
import { setDrawer, setNavPage } from '../reducers/modals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';

const centerPoints = {
  lat: Number(process.env.REACT_APP_LATITUDE),
  lng: Number(process.env.REACT_APP_LONGITUDE)
}

const options = {
  disableDefaultUI: true,
  gestureHandling: "greedy", // Allows moving the map with 1 finger instead of 2
  styles: [
    {
      featureType: "poi",
      stylers: [
          {
              visibility: "off",
          },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
          {
              visibility: "off",
          },
      ],
    },
    {
      featureType: "poi",
      stylers: [
          {
              visibility: "off",
          },
      ],
    },
    {
      featureType: "all",
      elementType: "labels",
      stylers: [
          {
              visibility: "off"
          },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [
          {
              visibility: "off"
          },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels",
      stylers: [
          {
              visibility: "on"
          },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
          {
              color: "#ffffff"
          },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
          {
              visibility: "off"
          },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
          {
              color: "#E2DFF4",
          },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
          {
              color: "#A7A2CF",
          },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry",
      stylers: [
          {
              color: "#7573B6",
          },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry",
      stylers: [
          {
              color: "#575C98",
          },
      ],
    },
  ],
  scaleControl: true,
  zoomControl: true,
  zoom: 15,
  minZoom: 13,
  maxZoom: 19
}

const Map = () => {
  const locations = useSelector((state : any) => state.locations)
  const filters = useSelector((state : any) => state.locations.filters)

  // Load Maps
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS!,
  })

  const map = useRef<any>();

  // Custom Icons
  const [customIcon, setCustomIcon] = useState<any>(null)
  const [customOfficeIcon, setCustomOfficeIcon] = useState<any>(null)

  // Check if in bound
  const checkInBound = () => {
    const bounds = map.current.getBounds();

    // Check if markers in bound
    const in_bound = locations.data.filter((location : Location) => {
      return bounds.contains({
        lat: location.lat,
        lng: location.lng
      })
    }).map((location : Location) => location.id);

    store.dispatch(setInBound(in_bound))
  }

  // Check inbound every time data changes
  useEffect(() => {
    if(locations.data.length > 0 && map.current){
      if(map.current.getBounds())
        checkInBound()
    }
  }, [locations.data, map])

  // On load
  const onLoad = (m : any) => {
    map.current = m;
    setCustomIcon({
      url: (process.env.PUBLIC_URL + "/imgs/marker.png"),
      scaledSize: new google.maps.Size(30, 60),
    })

    setCustomOfficeIcon({
      url: (process.env.PUBLIC_URL + "/imgs/marker_office.png"),
      scaledSize: new google.maps.Size(40, 80),
    })
  }

  // Unmount
  const onUnMount = () => {
    map.current = null;
  }

  // Check for selected state change
  useEffect(() => {
    if(locations.selected != -1){
      const index = locations.data.findIndex((loc : Location) => loc.id == locations.selected)
      if(index > -1){
        const loc = locations.data[index]

        map.current.panTo({
          lat: loc.lat,
          lng: loc.lng
        });
      }
    }
  }, [locations.selected])

  // Handle center click
  const handleCenter = () => {
    store.dispatch(setSelected(-1))
    
    map.current.panTo({
      lat: centerPoints.lat,
      lng: centerPoints.lng
    });
  }

  // Filtering
  const filteredList = () => {
    // filter bounds
    var filtered = locations.data.filter((loc : Location) => {
        return loc.name.toLowerCase().indexOf(locations.search.toLowerCase()) > -1
    })

    // Filter only open places
    if(filters.open_only){
        const dateNow = new Date();

        filtered = filtered.filter((loc : Location) => {
            const selected_day = loc.hours[Math.abs(dateNow.getDay()-1)]

            // Closed 
            if(selected_day.includes("Closed"))
                return false;
            
            const hours = selected_day.substr(selected_day.indexOf(" ") + 1).split(" – ");

            return dateNow >= moment(hours[0], 'HH:mm a').toDate() && dateNow <= moment(hours[1], 'HH:mm a').toDate();
        });
    }

    // Filter with filter variables
    filtered = filtered.filter((loc : Location) => {
        return (Number(loc.netlightRating.score) >= Number(filters.min_rating)) 
        && loc.options.some(f => filters.options.includes(f))
        && loc.types.some(f => filters.types.includes(f))
        && loc.foodTypes.some(f => filters.food_types.includes(f))
    })
    
    return filtered;
  }

  // Marker click
  const handleMarkerClick = (id : number) => {
    store.dispatch(setSelected(id))

    // Open up side menu if pressed
    let width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;

    if(width <= breakpoint.md){
      store.dispatch(setNavPage(0))
      store.dispatch(setDrawer(true))
    }
  }

  return isLoaded ? (
    <>
      <GoogleMap
          mapContainerStyle={{width: '100%', height: 'calc(var(--vh, 1vh) * 100)'}}
          center={centerPoints}
          // clickableIcons = {false}
          onLoad={onLoad}
          onUnmount={onUnMount}
          onBoundsChanged={checkInBound}
          options={options}
          onClick={() => store.dispatch(setSelected(-1))}
        >

          {
            filteredList().map((location : Location) => (<Marker
              key={`location-marker-${location.id}`}
              icon={customIcon ?? ""} onClick={() => handleMarkerClick(location.id)} 
              title={location.name}
              position={{ lat: location.lat, lng: location.lng }}
              opacity={0.8}
            />))
          }

        <Marker
            onClick={handleCenter}
            icon={customOfficeIcon ?? ""} 
            key={"netlight-office-marker"} 
            position={centerPoints} 
            title={'Netlight Office'} 
            opacity={0.95} 
            zIndex={9999}
          />
      </GoogleMap>

      <CenterButton onClick={handleCenter}>
        <FontAwesomeIcon icon={faBuilding}/>
        <div>Office</div>
      </CenterButton>
    </>
  ) : (<><CircularProgress /></>)
}

export default React.memo(Map);

const CenterButton = styled.div`
  width: 64px;
  height: 64px;
  position: fixed;
  bottom: 18px;
  right: 60px;
  background-color: #373737;
  border-radius: 100%;
  cursor: pointer;
  color: white;
  font-size: 22px;
  text-align: center;

  & svg{
    margin-top: 12px;
  }

  & div{
    font-size: 12px;
  }

  transition: background-color 0.25s linear 0s;
  box-shadow: 0 0 3px 1px rgb(0 0 0 / 50%);

  &:hover{
    background-color: #4d4d4d;
  }
`;