import { Grid, SwipeableDrawer } from "@mui/material";
import { useEffect, useState } from "react";
import LocationsList from "../components/LocationsList";
import Map from "../components/Map";
import Navigation from "../components/Navigation";
import store from "../lib/store";
import styled from 'styled-components'
import Avatar from "../components/Avatar";
import ActivityList from "../components/ActivityList";
import DeleteLocation from "../components/modals/DeleteLocation";
import CreateLocation from "../components/modals/CreateLocation";
import EditLocation from "../components/modals/EditLocation";
import ViewActivity from "../components/modals/ViewActivity";
import CreateActivity from "../components/modals/CreateActivity";
import ViewLocation from "../components/modals/ViewLocation";
import { useSelector } from "react-redux";
import { setDrawer, setModalCreateActivity, setModalCreateLocation, setModalDeleteActivity, setModalDeleteLocation, setModalEditLocation, setModalViewActivity, setModalViewLocation, setNavPage } from "../reducers/modals";
import { setFoodTypes, setLocations } from "../reducers/locations";
import { setActivities, setFilterActivityFoodTypes } from "../reducers/activity";
import DeleteActivity from "../components/modals/DeleteActivity";
import { breakpoint, device } from "../styles/breakpoints";
import TermsOfUse from "../components/TermsOfUse";
import useSSE from "../lib/sse";

const Home = () => {
    const modals = useSelector((state : any) => state.modals)

    // Use SSE
    useSSE()

    // Retrieve data function
    const fetchData = () => {
        // Get all locations
        fetch(`${process.env.REACT_APP_API_URL}/locations/`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json" 
                },
            }
        )
        .then((data) => data.json())
        .then((data) => {
            if(!data.message)
                store.dispatch(setLocations(data ? data : []))
        })
        .catch((err) => {})


        // Get all lunch trains
        fetch(`${process.env.REACT_APP_API_URL}/lunch-train/`,
        {
                method: "GET",
                headers: {
                    "Content-type": "application/json" 
                },
            }
        )
        .then((data) => data.json())
        .then((data) => {
            if(!data.message)
                store.dispatch(setActivities(data ? data : []));
        })
        .catch((err) => {})

        // Get all food types
        fetch(`${process.env.REACT_APP_API_URL}/food_types/`,
        {
                method: "GET",
                headers: {
                    "Content-type": "application/json" 
                },
            }
        )
        .then((data) => data.json())
        .then((data) => {
            if(!data.message){
                store.dispatch(setFoodTypes(data ? data : []));
                store.dispatch(setFilterActivityFoodTypes(data ? data : []))
            }
        })
        .catch((err) => {})
    }

    // Resize handler to hide drawer when going from Mobile -> Desktop size
    const handleResize = () => {
        if(window.innerWidth > breakpoint.md){
            store.dispatch(setDrawer(false))
        }
    }

    useEffect(() => {
        fetchData();

        // Resize event listener 
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize)
    }, [])

    // Get correct navigation list
    const getNavList = () => {
        switch(modals.nav_page){
            case 0:
                return <LocationsList/>
            case 1:
                return <ActivityList/>
            default:
                return <></>
        }
    }

    // Handle navigation click for mobile
    const handleNavClick = (page : number) => {
        // Open up side menu if pressed
        let width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;

        if(width <= breakpoint.md){
            // Hide if drawer is open
            if(modals.nav_page === page && modals.drawer)
                store.dispatch(setDrawer(false))
            else
                store.dispatch(setDrawer(true))
        }

        store.dispatch(setNavPage(page))
    }

    return (
        <>
            <CreateLocation open={modals.create_location} onClose={() => store.dispatch(setModalCreateLocation(false))} />

            <EditLocation open={modals.edit_location} onClose={(fallback: boolean) => store.dispatch(setModalEditLocation({value: false, fallback: fallback}))} />
            <DeleteLocation open={modals.delete_location} onClose={(fallback: boolean) => store.dispatch(setModalDeleteLocation({value: false, fallback: fallback}))} />
            <ViewLocation open={modals.view_location} onClose={() => store.dispatch(setModalViewLocation(false))} />
            <CreateActivity open={modals.create_activity} onClose={() => store.dispatch(setModalCreateActivity(false))} />
            <ViewActivity open={modals.view_activity} onClose={() => store.dispatch(setModalViewActivity(false))} />
            <DeleteActivity open={modals.delete_activity} onClose={(fallback: boolean) => store.dispatch(setModalDeleteActivity({value: false, fallback: fallback}))} />

            <Avatar/>
            
            <Grid container columns={2}>
                <Grid item md="auto">
                    <SideBar>
                        <Grid container flexDirection={"column"} height={"100%"}>
                            <Grid item xs="auto">
                                <Navigation onClick={(page : number) => handleNavClick(page)}/>
                            </Grid>
                            <NavHolder item md display={{xs: "none", sm: "none", md: "block"}}>
                                {getNavList()}
                            </NavHolder>
                            <Grid item xs="auto">
                                <TermsOfUse/>
                            </Grid>
                        </Grid>
                    </SideBar>

                    <SwipeableNav
                        anchor="left"
                        open={modals.drawer}
                        onClose={() => store.dispatch(setDrawer(false))}
                        onOpen={() => store.dispatch(setDrawer(true))}
                        swipeAreaWidth={0}
                    >

                        <Grid container flexDirection={"column"} height={"100%"}>
                            <Grid item xs>
                                <MobileNavListHolder>
                                    {getNavList()}
                                </MobileNavListHolder>
                            </Grid>
                            <Grid item xs="auto">
                                <TermsOfUse/>
                            </Grid>
                        </Grid>
                    </SwipeableNav>
                </Grid>
                <Grid item xs={2} sm={2} md>
                    <Map/>
                </Grid>
            </Grid>
        </>
    )
}

export default Home;

const MobileNavListHolder = styled.div`
    padding: 25px 15px 0 15px;
    height: calc(100% - 25px);
`

const SwipeableNav = styled(SwipeableDrawer)`
    z-index: 99;
    
    & .MuiPaper-root{
        max-width: 500px;
        height: calc(100%);
        padding-top: 60px;
        width: 100%;
        background: #4d4d4d;
        box-sizing: border-box;
    }
`

const SideBar = styled.div`
    width: 500px;
    height: 100%;


    @media ${device.sm_down}{
        position: fixed;
        z-index: 999;
        width: 100%;
        max-width: 100%;
        height: auto;
    }
`;

const NavHolder = styled(Grid)`
    background: #4d4d4d;
    padding: 25px 25px 0 25px;
`; 