import { SnackbarKey, SnackbarProvider } from 'notistack';
import {Routes, Route} from 'react-router-dom'
import Error from './Error';
import Home from './Home';
import { useRef } from 'react';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function Main(){
    const notistackRef = useRef<any>();
    const onClickDismiss = (key : SnackbarKey) => { 
        if(notistackRef.current)
          notistackRef.current.closeSnackbar(key);
    }
    
    return (
      <SnackbarProvider 
        maxSnack={3}
        hideIconVariant
        ref={notistackRef}
        action={(snackbarId) => (
          <IconButton 
            size="small"
            sx={{color: "white"}}
            onClick={() => onClickDismiss(snackbarId)}
          >
            <FontAwesomeIcon icon={faTimes} fontSize="inherit"/>
          </IconButton>
        )}
        >
        <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/*" element={<Error />}></Route>
        </Routes>
      </SnackbarProvider>
    )
}

export default Main;
