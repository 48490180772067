import styled from "styled-components";
import { device } from "../../styles/breakpoints";

interface PropsInterface{
    rating: number;
    dark?: boolean;
    large?: boolean;
    google?: boolean;
    center?: boolean;
}

const Rating = ({rating, large, google, center} : PropsInterface) => {
    return (
        <Holder large={large ? 1 : 0} center={center ? 1 : 0}>
            {(+rating.toFixed(1))}
            <NewProgress bar={Math.floor((rating / 5)*100)} google={google ? 1 : 0}/>
        </Holder>
    )
}

export default Rating;

const NewProgress = styled.div<{bar: number, google: number}>`
    display: inline-block;
    font-weight: 900;
    font-family: 'Font Awesome 6 Free';
    margin-left: 5px;

    &::before {
        content: ${({google}) => 
            google 
            ? `'\f005\f005\f005\f005\f005'`
            : `'\f004\f004\f004\f004\f004'`
        };
        letter-spacing: 3px;
        ${({bar, google}) => 
            google 
            ? `background: linear-gradient(90deg, #ffce44 0%, #ffce44 ${bar}%, #b2b2b2 ${bar}%, #b2b2b2 100%);`
            : `background: linear-gradient(90deg, #7573b6 0%, #7573b6 ${bar}%, #b2b2b2 ${bar}%, #b2b2b2 100%);`
        }
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

const Holder = styled.div<{large: number, center: number}>`
    display: flex;
    font-weight: 600;
    font-size: 18px;

    @media ${device.sm_down}{
        font-size: 14px;
    }

    ${({large}) => large && `
        font-size: 24px;
    `}
    ${({center}) => center && `
        justify-content: center;
    `}
`;