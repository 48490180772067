import { Divider, Grid, Button, Tooltip, Box } from "@mui/material";
import ModalLayout from "../lib/ModalLayout";
import styled from 'styled-components'
import { ModalBody } from "../../styles/layout";
import { useSelector } from "react-redux";
import { Activity } from "../../schemas/activity";
import Rating from "../item/Rating";
import Services from '../item/Services';
import { User } from '../../schemas/user'
import store from "../../lib/store";
import { setModalDeleteActivity, setModalSelected, setModalViewLocation } from "../../reducers/modals";

interface PropsInterface {
    open: boolean;
    onClose: () => void;
}

const ViewActivity = ({ open, onClose }: PropsInterface) => {
    const modals = useSelector((state: any) => state.modals);
    const activities = useSelector((state: any) => state.activities);
    const auth = useSelector((state: any) => state.auth);


    const getSelected = () => {
        const index = activities.data.findIndex((act: Activity) => act.eventID === modals.selected_activity)

        if (index >= 0)
            return activities.data[index]
        return null;
    }

    const handleView = () => {
        // set selected id
        if(getSelected()){

            store.dispatch(setModalSelected(getSelected().location.id))

            // Open modal
            store.dispatch(setModalViewLocation(true));
        }
    }

    // Check if the logged in user is the host of a lunch train
    const isHost = () => {
        return getSelected().creator.id == auth.userId;
    }

    const handleDelete = () => {
        store.dispatch(setModalDeleteActivity({value: true, fallback:true}))
    }

    return (
        <ModalLayout open={open} onClose={onClose} title={getSelected() ? getSelected().location.name + ' - ' + getSelected().time.substr(5, 11) : ""} width="800px">
            <>
                {
                    getSelected() && (
                        <Grid container columns={8}>
                            <Grid item sm={8} md={5}>
                                <ModalBody>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Comment>{ getSelected().text ?? "" }</Comment>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* {getPlaceInfo()} */}
                                            <Divider textAlign="left">
                                                Score
                                            </Divider>
                                            <Grid container columns={2} spacing={2} sx={{ mt: 1, mb: 1 }}>
                                                <Grid item xs={1}>
                                                    <Rating rating={getSelected() ? getSelected().location.netlightRating.score : 0} large center />
                                                    <RatingCount>Out of <b>{getSelected() ? getSelected().location.netlightRating.count : 0}</b></RatingCount>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Rating rating={getSelected() ? getSelected().location.googleRating.score : 0} large google center />
                                                    <RatingCount>Out of <b>{getSelected() ? getSelected().location.googleRating.count : 0}</b></RatingCount>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Divider textAlign="left">
                                                Food types
                                            </Divider>
                                            <Grid container spacing={1} sx={{mt: 1}}>
                                                {
                                                    getSelected().location.foodTypes && getSelected().location.foodTypes.map((type : string) => (
                                                        <Grid item xs="auto" key={`tag-${getSelected().location.id}-${type}`}>
                                                            <Tag light>
                                                                {type}
                                                            </Tag>
                                                        </Grid>
                                                        )
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Divider textAlign="left">
                                                Options
                                            </Divider>
                                            <Services
                                                dine_in={getSelected() ? getSelected().location.options.includes("Dine-in") : false}
                                                takeout={getSelected() ? getSelected().location.options.includes("Takeout") : false}
                                                delivery={getSelected() ? getSelected().location.options.includes("Delivery") : false}
                                            />
                                        </Grid>
                                        <Box display="flex" sx={{mt: 2, width: "100%"}} justifyContent="flex-end" alignItems="flex-end">
                                            {
                                                isHost() && (
                                                    <Button
                                                        variant="outlined"
                                                        size="large"
                                                        color="error"
                                                        sx={{mr: 1}}
                                                        onClick={handleDelete}
                                                    >
                                                        Delete
                                                    </Button>
                                                )
                                            }
                                            <Button
                                                variant="contained" 
                                                size="large" 
                                                color="primary"
                                                sx={{color: "white"}} 
                                                onClick={handleView}
                                            >
                                                View Place
                                            </Button>
                                        </Box>
                                    </Grid>
                                </ModalBody>
                            </Grid>
                            <Grid item sm={8} md={3} sx={{backgroundColor: "#5e5e5e", padding: "25px", borderBottomRightRadius: "15px", width: "100%"}}>
                                <Title>Participants</Title>
                                {
                                    getSelected().participants.map((participant: User) => (
                                        <Tooltip title={participant.name}>
                                            <ParticipantImage src={participant.avatar}/>
                                        </Tooltip>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    )
                }
            </>
        </ModalLayout>
    )
}

export default ViewActivity;

const Title = styled.h1`
    margin: 0;
    margin-bottom: 10px;
    font-size: 22px;
`

const ParticipantImage = styled.img`
    max-width: 50px;
    height: auto;
    border-radius: 100%;
`;

const ParticipantName = styled.div`
    color: white!important;
`;

const RatingCount = styled.div`
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
`;

const Comment = styled.div`
    font-size: 20px;
`;

const Tag = styled.div<{ light?: boolean }>`
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    color: ${({ light }) => light ? "#212121" : "white"};
    background: ${({ light }) => light ? "#eaeaea" : "#4D4D4D"};
    font-size: 12px;
`;