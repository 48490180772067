import { Button, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { useSelector } from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSearch, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { Location } from "../schemas/locations";
import ItemCard from "./item/ItemCard";
import store from "../lib/store";
import { setFilterShow, setSearch } from "../reducers/locations";
import Filters from "./lib/Filters";
import { useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import moment from 'moment'
import { setModalCreateLocation } from "../reducers/modals";
import styled from 'styled-components'
import { DividerBadges, DividerFloatRight, EmptyList } from "../styles/layout";

const LocationsList = () => {
    const locations = useSelector((state : any) => state.locations)
    const filters = useSelector((state : any) => state.locations.filters)

    const filteredList = (skipBoundary?: boolean) => {
        // filter bounds
        if(skipBoundary)
            var filtered = locations.data.filter((loc : Location) => locations.in_bound.indexOf(loc.id) === -1)
        else
            var filtered = locations.data.filter((loc : Location) => locations.in_bound.indexOf(loc.id) > -1)

        // Filter with filter variables
        filtered = filtered.filter((loc : Location) => {
            return (Number(loc.netlightRating.score) >= Number(filters.min_rating)) 
            && loc.options.some(f => filters.options.includes(f))
            && loc.types.some(f => filters.types.includes(f))
            && loc.foodTypes.some(f => filters.food_types.includes(f))
        })

        // Filter only open places
        if(filters.open_only){
            const dateNow = new Date();

            filtered = filtered.filter((loc : Location) => {
                const selected_day = loc.hours[Math.abs(dateNow.getDay()-1)]

                // Closed 
                if(selected_day.includes("Closed"))
                    return false;
                
                const hours = selected_day.substr(selected_day.indexOf(" ") + 1).split(" – ");

                return dateNow >= moment(hours[0], 'HH:mm a').toDate() && dateNow <= moment(hours[1], 'HH:mm a').toDate();
            });
        }

        // filter search
        filtered = filtered.filter((loc : Location) => {
            return loc.name.toLowerCase().indexOf(locations.search.toLowerCase()) > -1
        })

        // Sorting
        filtered = filtered.sort((a: Location, b: Location) => {
            if(filters.sort_by === "Rating")
                return b.netlightRating.score-a.netlightRating.score
            else if(filters.sort_by === "Distance"){
                const distance_a = Number(a.distance.split(" ")[0]) * ((a.distance.split(" ")[1] === "km") ? 1000 : 1); 
                const distance_b = Number(b.distance.split(" ")[0]) * ((b.distance.split(" ")[1] === "km") ? 1000 : 1); 

                return distance_a-distance_b;
            }

            return 0;
        });
        
        return filtered;
    }

    // Scroll to location card
    const scrollToSelected = (id : number) => {
        const list_element = document.getElementById("location_list");
        const element = document.getElementById(`location-${id}`);

        if(element){
            list_element!.scrollTo({
                top: element.offsetTop - list_element!.offsetTop,
                left: 0,
                behavior:"smooth"
            });
        }
            
    }

    useEffect(() => {
        scrollToSelected(locations.selected)
    }, [locations.selected])

    return (
        <Grid container flexDirection={"column"} height={"100%"}>
            <Grid item xs="auto">
                <Grid container columns={4} spacing={1} alignItems="center">
                    <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Search</InputLabel>
                            <OutlinedInput
                                type="text"
                                placeholder="Search added places..."
                                value={locations.search}
                                onChange={(e) => store.dispatch(setSearch(e.target.value))}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <Divider textAlign="left"> 
                            <Button
                                sx={{color: "white"}}
                                onClick={() => store.dispatch(setFilterShow(!filters.show))}
                            >
                                Filters <FontAwesomeIcon style={{transform: (!filters.show) ? "rotate(-90deg)" : "", marginLeft: 6}} icon={faSortDown} />
                            </Button>
                        </Divider>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container columns={3} spacing={1} alignItems="center">
                            {
                                filters.show && (<Filters/>)
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Divider textAlign="left" sx={{position: "relative", mb: "15px", mt: 1, textTransform: "uppercase"}}>
                    Places 

                    <DividerFloatRight>
                        <Button 
                            variant="contained" 
                            size="small" 
                            sx={{color: "white"}}
                            onClick={() => store.dispatch(setModalCreateLocation(true))}
                            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                        >
                            Add place
                        </Button>
                    </DividerFloatRight>
                </Divider>
            </Grid>
            <Grid item xs style={{overflowY: "auto"}} id="location_list">
                
            <TransitionGroup>
                {
                    filteredList().length === 0 && filteredList(true).length === 0
                    ? (
                        <CSSTransition
                        timeout={400}
                        classNames="fade"
                        key={`location-empty`}
                        >
                            <EmptyList>
                                {
                                    locations.search
                                    ? (<>
                                        The place you were looking for is not added yet.<br/>
                                        Add your favorite place now.
                                    </>)
                                    : (<>
                                        It looks pretty empty here.<br/>
                                        Fill out the map with your favorite place.
                                    </>)
                                }
                            </EmptyList>
                        </CSSTransition>)
                    : (
                        <CSSTransition
                            timeout={400}
                            classNames="fade"
                            key={`location-item-results`}
                        >
                            <TransitionGroup>
                            {
                                (locations.search && filteredList().length > 0) &&
                                (
                                    <CSSTransition
                                    timeout={400}
                                    classNames="fade"
                                    key={`location-divider-onmap`}
                                    >
                                            <DividerBadges>
                                                On map
                                            </DividerBadges>
                                    </CSSTransition>
                                )
                            }
                            {
                                filteredList().map((location : Location) => (
                                <CSSTransition
                                    timeout={400}
                                    classNames="fade"
                                    key={`location-${location.id}`}
                                    >
                                    <ItemCard
                                        id={`location-${location.id}`}
                                        selected={locations.selected === location.id}
                                        key={`item-card-${location.id}`}
                                        item={location}
                                    />
                                </CSSTransition>
                                ))
                            }
                            {
                                (locations.search && filteredList(true).length > 0) &&
                                (
                                    <CSSTransition
                                    timeout={400}
                                    classNames="fade"
                                    key={`location-divider-others`}
                                    >
                                            <DividerBadges>
                                                Others
                                            </DividerBadges>
                                    </CSSTransition>
                                )
                            }
                            {
                                locations.search && filteredList(true).map((location : Location) => (
                                <CSSTransition
                                    timeout={400}
                                    classNames="fade"
                                    key={`location-${location.id}`}
                                    >
                                    <ItemCard
                                        id={`location-${location.id}`}
                                        selected={locations.selected === location.id}
                                        key={`item-card-${location.id}`}
                                        item={location}
                                    />
                                </CSSTransition>
                                ))
                            }
                            </TransitionGroup>
                        </CSSTransition>
                    )
                }
                </TransitionGroup>
            </Grid>
        </Grid>
    )
}

export default LocationsList;
