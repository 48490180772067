import { Box, Button, Grid } from "@mui/material";
import styled from "styled-components";
import store from '../../lib/store'
import { setSelected } from "../../reducers/locations";
import { useEffect, useState } from "react";
import { User } from '../../schemas/user';
import { useSelector } from 'react-redux';
import { Activity } from "../../schemas/activity";
import ActivityTime from "./ActivityTime";
import { joinActivity, leaveActivity } from "../../reducers/activity";
import { useSnackbar } from "notistack";
import { setModalSelectedActivity, setModalViewActivity } from '../../reducers/modals'
import { device } from "../../styles/breakpoints";
import { faUsers,faPersonWalking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DistanceTag } from "../../styles/layout";



interface PropsInterface {
    item: Activity;
}

const ActivityCard = ({ item }: PropsInterface) => {
    const { enqueueSnackbar } = useSnackbar();

    const [participant, setParticipant] = useState<boolean>(false);
    const auth = useSelector((state: any) => state.auth)

    const handleClick = (e : any) => {
        e.stopPropagation();

        if(participant){

            // Leave
            fetch(`${process.env.REACT_APP_API_URL}/lunch-train/${item.eventID}/leave`,
                {
                    method: "POST"
                }
            ).then((res) => res.json())
                .then((data) => {
                    // Change this
                    store.dispatch(leaveActivity({ user_id: auth.userId, id: item.eventID }))
                })
                .catch((err) => {
                    enqueueSnackbar(`Failed to leave activity`, {
                        variant: "error"
                    });
                })
        }
        else {
            // Join
            fetch(`${process.env.REACT_APP_API_URL}/lunch-train/${item.eventID}/join`,
                {
                    method: "POST"
                }
            ).then((res) => res.json())
                .then((data) => {
                    // Change this
                    store.dispatch(joinActivity({ user: { ...auth.user, id: auth.userId }, id: item.eventID }))
                })
                .catch((err) => {
                    enqueueSnackbar(`Failed to join activity`, {
                        variant: "error"
                    });
                })
        }

        // Update button
        setParticipant(!participant)
    }

    const activityPopUp = (e: any) => {
        e.stopPropagation();

        // set selected id
        store.dispatch(setModalSelectedActivity(item.eventID))

        // Open modal
        store.dispatch(setModalViewActivity(true));
    }

    useEffect(() => {
        setParticipant(item.participants.findIndex((u: User) => u.id == auth.userId) > -1)
    }, [])

    return (
        <Holder onClick={activityPopUp}>
            <Grid container>
                <Grid item xs>
                    <Title
                        onClick={(e) => {e.stopPropagation(); store.dispatch(setSelected(item.location.id))}}
                    >{item.location.name}</Title>
                    <ActivityTime time={item.time} />
                    <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>
                    {
                        item.participants.length + 1
                    }
                    <DistanceTag>{item.location.distance}</DistanceTag>
                    <DistanceTag>
                        <FontAwesomeIcon icon={faPersonWalking}></FontAwesomeIcon>
                        {item.location.duration}
                    </DistanceTag>
                </Grid>
                <Grid item xs="auto">
                    <Image src={item.creator.avatar}>
                        <SmallAvatarsHolder>
                            {
                                item.participants.length <= 3
                                ? item.participants.slice(0, 3).map((u : User, index: number) => (<SmallAvatar key={`smallavatar-${item.eventID}-${index}`} src={u.avatar} />))
                                : (
                                    <>
                                        {item.participants.slice(0, 2).map((u : User, index: number) => (<SmallAvatar key={`smallavatar-${item.eventID}-${index}`} src={u.avatar} />))}
                                        <SmallAvatar>
                                            +{item.participants.length - 2}
                                        </SmallAvatar>
                                    </>
                                )
                            }
                        </SmallAvatarsHolder>
                    </Image>
                </Grid>
            </Grid>
            <Grid container columns={2} spacing={1} alignItems="center">
                {
                    item.creator.id != auth.userId
                    && (
                        <Grid item xs="auto">
                            <Button
                                onClick={handleClick}
                                color={participant ? "error" : "success"}
                                variant="outlined"
                            >
                                {
                                    participant
                                        ? "Leave"
                                        : "Join"
                                }
                            </Button>
                        </Grid>
                    )
                }
            </Grid>
        </Holder>
    )
}

export default ActivityCard;

const SmallAvatar = styled.div<{ src?: string }>`
    background-color: #7573B6;
    background-image: url(${({ src }) => src ?? ""});
    background-size: cover;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;

    font-size: 10px;
    line-height: 24px;
    color: white;
    text-align: center;

    @media ${device.xs}{
        height: 15px;
        width: 15px;
    }
`;

const SmallAvatarsHolder = styled.div`
    position: relative;
    height: 100%;

    & ${SmallAvatar}:nth-child(2){
        right: 10px;
    }

    & ${SmallAvatar}:nth-child(3){
        right: 20px;
    }
`;

const Title = styled.h2`
    margin: 5px 0 10px 0;
    display: inline-block;
    @media ${device.sm_down}{
        font-size: 18px;
    }
`;

const Holder = styled.div`
    background: white;
    padding: 25px;
    border-radius: 10px;
    margin-top: 15px;
    cursor: pointer;
`;

const Image = styled.div<{ src: string }>`
    background-image: url(${({ src }) => src});
    background-size: cover;
    height: 75px;
    width: 75px;
    border-radius: 100%;
    display: block;
    
    @media ${device.xs}{
        height: 50px;
        width: 50px;
    }
`;