import { faPlusCircle, faSearch, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Button, Divider, Grid, InputAdornment, Modal, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addActivity } from "../../reducers/activity";
import { Activity } from "../../schemas/activity";
import store from "../../lib/store";
import { ModalBody } from "../../styles/layout";
import DateTime from "../lib/DateTime";
import ModalLayout from "../lib/ModalLayout";
import { Location } from "../../schemas/locations";

interface PropsInterface {
    open: boolean;
    onClose: () => void;
}

interface FormInterface {
    time: string;
    locationid: number;
    text: string;
}

const CreateActivity = ({ open, onClose }: PropsInterface) => {
    const { enqueueSnackbar } = useSnackbar();

    const locations = useSelector((state: any) => state.locations);

    const [autoOpen, setAutoOpen] = useState<boolean>(false);

    const [form, setForm] = useState<FormInterface>({
        locationid: -1,
        time: "",
        text: ""
    })

    const [inputValue, setInputvalue] = useState<string>("");

    useEffect(() => {
        // Check if a location is selected
        if(locations.selected >= 0){
            let index = locations.data.findIndex((val : Location) => locations.selected === val.id)

            if(index >= 0){
                setForm({...form, locationid: locations.selected})
                setInputvalue(locations.data[index].name)
            }
        }
        else{
            setForm({...form, locationid: -1})
            setInputvalue("")
        }
    }, [open])

    // Add
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const handleAdd = async () => {
        setButtonLoading(true);

        // Add to location
        await fetch(`${process.env.REACT_APP_API_URL}/lunch-train/`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    "Access-Control-Allow-Origin": '*'
                },
                body: JSON.stringify(form)
            }
        ).then((res) => res.json())
            .then((data) => {
                if (data.message)
                    enqueueSnackbar('Failed to add lunch train', {
                        variant: "error"
                    });

                store.dispatch(addActivity(data as Activity))
                handleClose();
            })
            .catch((err) => {
                enqueueSnackbar('Failed to add lunch train', {
                    variant: "error"
                });
                handleClose();
            })

        setButtonLoading(false);
    }
    //Flush input fields on close
    const handleClose = () => {
        setForm({
            locationid: -1,
            time: "",
            text: ""
        })
        onClose();
    }

    return (
        <ModalLayout open={open} onClose={handleClose} title="Create Lunch train" width="420px">
            <ModalBody>
                <Grid container columns={2} spacing={2}>
                    <Grid item xs={2}>
                        <Divider textAlign="left">Lunch train info</Divider>
                    </Grid>
                    <Grid item xs={2}>
                        <Autocomplete
                            inputValue={inputValue}
                            open={autoOpen}
                            onOpen={() => {
                                setAutoOpen(true);
                            }}
                            onClose={() => {
                                setAutoOpen(false);
                            }}
                            onChange={(e: FormEvent, reason: any) => {
                                if (reason && reason.id){
                                    setInputvalue(reason.name)
                                    setForm({ ...form, locationid: reason.id })
                                }
                                else{
                                    setInputvalue("")
                                    setForm({ ...form, locationid: -1 })
                                }
                            }}
                            noOptionsText={"Nothing found..."}
                            clearIcon={<></>}
                            popupIcon={<></>}
                            getOptionLabel={(option: any) => option.name}
                            options={locations.data}
                            loadingText="Searching..."
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={"outlined"}
                                    label={"* Select location"}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setInputvalue(e.target.value)}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faSearch} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            multiline
                            fullWidth
                            label="Comment"
                            placeholder="Optional comment"
                            rows={3}
                            value={form.text}
                            onChange={(e) => setForm({ ...form, text: e.target.value })}
                        />
                    </Grid>


                    <Grid item xs={2}>
                        <Divider textAlign="left">Departure time</Divider>
                    </Grid>

                    <DateTime onUpdate={(time) => setForm({ ...form, time })} />

                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                            <LoadingButton
                                loading={buttonLoading}
                                variant="contained"
                                size="large"
                                sx={{ color: "white" }}
                                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                                onClick={handleAdd}
                                loadingPosition="start"
                            >
                                Add
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>

            </ModalBody>
        </ModalLayout>
    )
}

export default CreateActivity;
