import styled from 'styled-components'

const TermsOfUse = () => {

    return (
        <Holder>
            <ul>
                <li>
                    Map data &copy;{new Date().getFullYear()} Google
                </li>
                <li>
                    <a href="https://cloud.google.com/maps-platform/terms" target="_">Terms of Use</a>
                </li>
            </ul>
        </Holder>
    )
}

export default TermsOfUse;

const Holder = styled.div`
    background: #373737;
    font-size: 10px;
    padding: 10px;
    text-align: center;
    color: white;

    & ul{
        list-style: none;
        margin: 0;
        padding: 0;

        & li{
            display: inline-block;
            padding: 0 5px;

            &:not(:first-of-type){
                border-left: 1px solid white;
            }
        }
    }
`
