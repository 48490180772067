import { Grid, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";

import styled from 'styled-components';

interface PropsInterface {
    value: any;
    onChange: (value : any) => void;
}

const Discount = ({value, onChange} : PropsInterface) => {
    const [intValue, setIntValue] = useState(value)
    
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value;

        // Check if the first digit is 0 and it is not a comma that is being added
        if(val.length === 2){
            if(val[0] === "0" && val[1] !== ","){
                val = val.slice(1)
            }
        }
        else if(val.length === 0)
            val = "0"

        setIntValue(val)
        onChange(val);
    }

    return (
        <Grid container>
            <Grid item xs>
                <TextFieldRadiusFix
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Discount"
                    size="small"
                    value={intValue}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 99.9, step: 0.5 } }}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs="auto">
                <PercentageHolder>
                    %
                </PercentageHolder>
            </Grid>
        </Grid>
    )
}

export default Discount;

const TextFieldRadiusFix = styled(TextField)`
    & .MuiInputBase-root{

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`


const PercentageHolder = styled.div`
    background: #373737;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    color: white;
    font-weight: 600;
    font-size: 14px;
    padding: 11px;
    user-select: none;
`