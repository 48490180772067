import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, Divider, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addFoodType, DefaultTypes, editLocation } from "../../reducers/locations";
import { setModalDeleteLocation } from "../../reducers/modals";
import { Location } from "../../schemas/locations";
import store from "../../lib/store";
import { ModalBody } from "../../styles/layout";
import ModalLayout from "../lib/ModalLayout";
import SearchableSelect from "../lib/SearchableSelect";

interface PropsInterface{
    open: boolean;
    onClose: (fallback: boolean) => void;
}

const EditLocation = ({open, onClose} : PropsInterface) => {
    const locations = useSelector((state : any) => state.locations)
    const modals = useSelector((state : any) => state.modals)

    const getSelected = () => {
        const index = locations.data.findIndex((loc : Location) => loc.id === modals.selected)

        if(index >= 0)
            return locations.data[index]
        return null;
    }

    // Snackbar
    const { enqueueSnackbar } = useSnackbar();

    // Form data
    const [form, setForm] = useState<any>({
        types: DefaultTypes,
        discount: {
            percentage: 0,
            comment: ""
        },
        description: "",
        foodTypes: [],
    })


    useEffect(() => {
        // Reset
        if(getSelected())
            setForm({...getSelected()})
    }, [open])

    // Validation
    const validate = () => {
        // check if type is selected
        if(form.type.length === 0){
            enqueueSnackbar('Must specify what it\'s good for', {variant: "error"})
            return false;
        }
        
        // check if food type is selected
        if(form.foodTypes.length === 0){
            enqueueSnackbar('No food type selected', {variant: "error"})
            return false;
        }

        return true;
    }

    // Handle edit
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const handleEdit = async () => {
        if(!validate())
            return;

        setButtonLoading(true);

        await fetch(`${process.env.REACT_APP_API_URL}/locations/${modals.selected}`,
            {
                method: "PATCH",
                body: JSON.stringify({...form})
            }
        ).then((res) => res.json())
        .then((data) => {
            enqueueSnackbar(`Succesfully updated ${getSelected().name}`, {
                variant: "success"
            });

            store.dispatch(editLocation({id: modals.seleted, data: form}))

            onClose(false)
        })
        .catch((err) => {
            enqueueSnackbar(`Failed to update ${getSelected().name}`, {
                variant: "error"
            });
        })

        setButtonLoading(false);
    }

    // Creating food type
    const handleAddFoodType = async (name : string) => {
        await fetch(`${process.env.REACT_APP_API_URL}/food_types/`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json" 
                },
                body: JSON.stringify({name})
            }
        ).then((res) => res.json())
        .then((data) => {
            if(data.message){
                enqueueSnackbar('Food type already exists', {
                    variant: "error"
                });
                return;
            }

            enqueueSnackbar(`Food type added`, {
                variant: "success"
            });
            store.dispatch(addFoodType(name))
            setForm({...form, foodTypes: [...form.foodTypes, name]})
        })
        .catch((err) => {
            enqueueSnackbar('Failed to add food type', {
                variant: "error"
            });
        })
    }

    return (
        <ModalLayout open={open} onClose={() => onClose(true)} title={getSelected() ? getSelected().name : ""} width="420px">
            <ModalBody>
                <Grid container columns={2} spacing={2}>
                    <Grid item xs={2}>
                        <Divider textAlign="left">Place info</Divider>
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <SearchableSelect 
                            label="* Good for"
                            items={DefaultTypes} 
                            selectedItems={form.types} 
                            onItemChange={(items) => store.dispatch(setForm({...form, types: items}))} 
                            checkAll 
                            disableSearch
                        />
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <SearchableSelect 
                            label="* Food"
                            items={locations.food_types}
                            selectedItems={form.foodTypes} 
                            onItemChange={(items) => setForm({...form, foodTypes: items})} 
                            add 
                            onAdd={handleAddFoodType} 
                            tooltip="Add foodtype"
                            placeholder="Search/add foodtype..."
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <TextField
                            label="Description"
                            InputLabelProps={{ shrink: true }}
                            multiline
                            fullWidth
                            placeholder="Description of place"
                            rows={3}
                            value={form.description}
                            onChange={(e) => setForm({...form, description: e.target.value})}
                        />
                    </Grid>
                    

                    <Grid item xs={2}>
                        <Divider textAlign="left">Discount</Divider>
                    </Grid>

                    <Grid item xs={2} sm={1}>
                        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                            <TextField
                                fullWidth
                                label="Discount"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                value={form.discount.percentage}
                                type="number"
                                InputProps={{ inputProps: { min: 0, max: 99.9, step: 0.5 } }}
                                onChange={(e) => {
                                    setForm({...form, discount: {...form.discount, percentage: Number(e.target.value)}})
                                }}
                            />
                        </Box>
                    </Grid>
                    
                    <Grid item xs={2} sm={1}>
                        <TextField
                            label="Discount note"
                            InputLabelProps={{ shrink: true }}
                            multiline
                            fullWidth
                            placeholder="Instructions for applying discount"
                            rows={2}
                            value={form.discount.comment}
                            onChange={(e) => setForm({...form, discount: {...form.discount, comment: e.target.value}})}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Box display="flex" gap={1} justifyContent="flex-end" alignItems="flex-end">
                            <Button
                                variant="outlined" 
                                size="large" 
                                color="error"
                                onClick={() => store.dispatch(setModalDeleteLocation({value: true}))}
                            >
                                Delete
                            </Button>

                            <LoadingButton
                                loading={buttonLoading}
                                variant="contained" 
                                size="large" 
                                color="primary"
                                sx={{color: "white"}} 
                                onClick={handleEdit}
                            >
                                Update
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
                
                
            </ModalBody>
        </ModalLayout>
    )
}

export default EditLocation;