import { createTheme } from '@mui/material/styles';

let theme = createTheme()

export default createTheme(theme, {
    palette: {
        primary: {
            main: '#7573B6',
            light: "#A7A2CF",
            dark: "#575C98"
        },
        success: {
            main: '#5cb85c',
            light: "#63c763",
            dark: "#499348"
        }
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    [theme.breakpoints.only("xs")]: {
                        fontSize: 12,
                        padding: 0,
                        paddingLeft: 10,
                        paddingRight: 10
                    }
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    maxHeight: "300px"
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    [theme.breakpoints.only("xs")]: {
                        fontSize: 12
                    },
                    background: "white",
                    borderRadius: 5,
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: 'none',
                        color: "#575c98"
                    },
                    
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 600,
                    [theme.breakpoints.only("xs")]: {
                        fontSize: 12
                    },
                    color: "white",
                    "&::before": {
                      borderColor: "#a1a1a1"
                    },
                    "&::after": {
                      borderColor: "#a1a1a1"
                    },
                }
            }
        },
        MuiInputLabel: { 
            styleOverrides:{
                root: { 
                    padding: 5,
                    borderRadius: 5,
                    marginBottom: 5,
                    background: "white"
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root:{
                    fontSize: 14,
                    [theme.breakpoints.only("xs")]: {
                        fontSize: 12
                    },
                    color: "black!important"
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides:{
                root:{
                    fontSize: 14,
                    [theme.breakpoints.only("xs")]: {
                        fontSize: 12
                    },
                    color: "white"
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    [theme.breakpoints.only("xs")]: {
                        fontSize: 12
                    }
                }
            }
        },
        MuiButton:{
            styleOverrides:{
                root: {
                    fontSize: 14,
                    [theme.breakpoints.only("xs")]: {
                        fontSize: 12
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides:{
                root: {
                    fontSize: 14,
                    [theme.breakpoints.only("xs")]: {
                        fontSize: 12
                    }
                }
            }
        }
    }
});