import { useEffect, useState } from 'react';
import {useSelector} from 'react-redux';
import { setUser } from '../../reducers/auth';
import store from '../../lib/store';

interface AuthProps{
    component: JSX.Element;
    fail: JSX.Element;
}

const RequireAuth = ({component, fail} : AuthProps) => {
    const auth = useSelector((state : any) => state.auth)
    const [loading, setLoading] = useState<boolean>(true);

    // Get user info
    const getUserData = async () => {
        // FETCH USER DATA
        await fetch(`${process.env.REACT_APP_API_URL}/users/${auth.userId}`,
            {
                method: "GET"
            }
        )
        .then((res) => res.json())
        .then((data) => {
            if(data.error)
                return;
            
            store.dispatch(setUser(data))
        })
        .catch((err) => {})

        setLoading(false);
    }
    
    useEffect(() => {
        if(auth.userId)
            getUserData();
        else
            setLoading(false)
    }, [auth.userId])

    return (
        loading
        ? null
        : (
            auth.token
            ? (auth.user) ? component : null
            : fail
        )
    )
}

export default RequireAuth;