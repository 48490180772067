import { faMapLocation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/system';
import styled from 'styled-components';

interface PropsInterface{
    address: string;
    lat: string;
    lng: string;
}

const OpenMap = ({address, lat, lng} : PropsInterface) => {

    const handleOpen = () => {
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1;
        if(isAndroid) {
            (window as Window).location = `geo:${lat},${lng}`;
        }
        else {
            (window as Window).location = `http://maps.apple.com/?ll=${lat},${lng}`;
        }
    }
    
    return (<Box display="flex" sx={{cursor: "pointer"}} onClick={handleOpen}>
        <HolderIcon>
            <FontAwesomeIcon icon={faMapLocation}/>
        </HolderIcon>
        <Holder>
            {address}
        </Holder>
    </Box>)
}

export default OpenMap;

const Holder = styled.div`
    background: #e1e1e1;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100%;
    padding: 10px;
    color: #212121;
`

const HolderIcon = styled.div`
    background: #7573b6;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px;
`