import {combineReducers} from "redux";
import { authReducer } from "./auth";
import { locationsReducer } from "./locations";
import { modalsReducer } from "./modals";
import { usersReducer } from "./users";
import { activitiesReducer } from "./activity";

export default combineReducers({
    auth: authReducer,
    locations: locationsReducer,
    modals: modalsReducer,
    users: usersReducer,
    activities: activitiesReducer,
});