import { FoodType } from "../schemas/food_type";
import { Location } from "../schemas/locations";

export const DefaultTypes = ["Mentor", "Lunches", "Client", "Events"]
export const DefaultOptions = ["Dine-in", "Takeout", "Delivery"]

// Schema
interface stateInterface{
    data: Location[];
    food_types: string[]; 
    search: string;
    selected: number;
    in_bound: number[];
    filters: {
        show: boolean;
        types: string[];
        min_rating: number;
        options: string[];
        food_types: string[];
        sort_by: string;
        open_only: boolean;
    }
}

const initialState : stateInterface = {
    data: [],
    food_types: [],
    search: "",
    selected: -1,
    in_bound: [],
    filters: {
        show: false,
        min_rating: 0,
        types: DefaultTypes,
        options: DefaultOptions,
        food_types: [],
        sort_by: "",
        open_only: false
    }
};

//--- Actions ---//
const SET_SEARCH = "SET_SEARCH";

// Locations
const SET_LOCATIONS = "SET_LOCATIONS";
const ADD_LOCATION = "ADD_LOCATION";
const EDIT_LOCATION = "EDIT_LOCATION";
const REMOVE_LOCATION = "REMOVE_LOCATION";
const UPDATE_LOCATION_REVIEW = "UPDATE_LOCATION_REVIEW";

const SET_SELECTED = "SET_SELECTED";
const SET_IN_BOUND = "SET_IN_BOUND";
const SET_FOOD_TYPES = "SET_FOOD_TYPES";
const ADD_FOOD_TYPE = "ADD_FOOD_TYPE";

// Filters
const SET_FILTER_MIN_RATING = "SET_FILTER_MIN_RATING";
const SET_FILTER_TYPES = "SET_FILTER_TYPES";
const SET_FILTER_OPTIONS = "SET_FILTER_OPTIONS";
const SET_FILTER_FOOD_TYPES = "SET_FILTER_FOOD_TYPES";
const SET_FILTER_SHOW = "SET_FILTER_SHOW";
const SET_FILTER_SORT_BY = "SET_FILTER_SORT_BY";
const SET_FILTER_OPEN_ONLY = "SET_FILTER_OPEN_ONLY";

export const setSearch = (payload : string) => {
    return { type: SET_SEARCH, payload};
}

export const setLocations = (payload : Location[]) => {
    return { type: SET_LOCATIONS, payload};
}

export const addLocation = (payload : Location) => {
    return { type: ADD_LOCATION, payload};
}

export const editLocation = (payload : {data: Location, id: number}) => {
    return { type: EDIT_LOCATION, payload};
}

export const removeLocation = (payload : number) => {
    return { type: REMOVE_LOCATION, payload};
}

export const updateLocationReview = (payload : {count: number, score: number, id: number}) => {
    return { type: UPDATE_LOCATION_REVIEW, payload};
}

export const setFoodTypes = (payload : string[]) => {
    return { type: SET_FOOD_TYPES, payload};
}

export const addFoodType = (payload : string) => {
    return { type: ADD_FOOD_TYPE, payload};
}

export const setSelected = (payload : number) => {
    return { type: SET_SELECTED, payload};
}

export const setInBound = (payload : number[]) => {
    return { type: SET_IN_BOUND, payload};
}

export const setFilterRating = (payload : number) => {
    return { type: SET_FILTER_MIN_RATING, payload};
}

export const setFilterTypes = (payload : string[]) => {
    return { type: SET_FILTER_TYPES, payload};
}

export const setFilterOptions = (payload : string[]) => {
    return { type: SET_FILTER_OPTIONS, payload};
}

export const setFilterFoodTypes = (payload : string[]) => {
    return { type: SET_FILTER_FOOD_TYPES, payload};
}

export const setFilterShow = (payload : boolean) => {
    return { type: SET_FILTER_SHOW, payload};
}

export const setFilterSortBy = (payload : string) => {
    return { type: SET_FILTER_SORT_BY, payload};
}

export const setFilterOpenOnly = (payload : boolean) => {
    return { type: SET_FILTER_OPEN_ONLY, payload};
}


export const locationsReducer = (state = initialState, action : any) => {
    if(action.type === SET_SEARCH){
        // Set new list
        return Object.assign({}, state, {
            search: action.payload
        });
    }

    else if(action.type === SET_LOCATIONS){
        // Set new list
        return Object.assign({}, state, {
            data: action.payload
        });
    }
    else if(action.type === ADD_LOCATION){
        const new_data = [...state.data];

        // Skip adding duplicates
        if(new_data.findIndex((loc : Location) => loc.id == action.payload.id) === -1)
            new_data.push(action.payload)

        // Set new list
        return Object.assign({}, state, {
            data: new_data
        });
    }
    else if(action.type === EDIT_LOCATION){
        const new_data = [...state.data];
        const index = new_data.findIndex((loc : Location) => loc.id == action.payload.id)
        new_data[index] = {...new_data[index], ...action.payload.data}

        // Set new list
        return Object.assign({}, state, {
            data: new_data
        });
    }
    else if(action.type === REMOVE_LOCATION){
        const new_data = state.data.filter((loc: Location) => loc.id != action.payload);

        // Set new list
        return Object.assign({}, state, {
            data: new_data
        });
    }
    else if(action.type === UPDATE_LOCATION_REVIEW){
        const new_data = [...state.data];
        const index = new_data.findIndex((loc : Location) => loc.id == action.payload.id)
        new_data[index] = {...new_data[index], netlightRating: {score: action.payload.score, count: action.payload.count}}

        // Set new list
        return Object.assign({}, state, {
            data: new_data
        });
    }

    else if(action.type === SET_FOOD_TYPES){
        // Set new list
        return Object.assign({}, state, {
            food_types: action.payload,
            filters: {
                ...state.filters, food_types: action.payload
            }
        });
    }
    else if(action.type === ADD_FOOD_TYPE){
        const new_data = [...state.food_types]
        if(!state.food_types.includes(action.payload)){
            new_data.push(action.payload)
        }

        // Set new list
        return Object.assign({}, state, {
            food_types: new_data,
            // Add to filters when adding to food type
            filters: {
                ...state.filters, food_types: [...state.filters.food_types, action.payload]
            }
        });
    }

    else if(action.type === SET_SELECTED){
        // Set new list
        return Object.assign({}, state, {
            selected: action.payload
        });
    }
    else if(action.type === SET_IN_BOUND){
        // Set new list
        return Object.assign({}, state, {
            in_bound: action.payload
        });
    }

    else if(action.type === SET_FILTER_MIN_RATING){
        // Set new list
        return Object.assign({}, state, {
            filters: {...state.filters, min_rating: action.payload} 
        });
    }
    else if(action.type === SET_FILTER_TYPES){
        // Set new list
        return Object.assign({}, state, {
            filters: {...state.filters, types: action.payload} 
        });
    }
    else if(action.type === SET_FILTER_OPTIONS){
        // Set new list
        return Object.assign({}, state, {
            filters: {...state.filters, options: action.payload} 
        });
    }
    else if(action.type === SET_FILTER_FOOD_TYPES){
        // Set new list
        return Object.assign({}, state, {
            filters: {...state.filters, food_types: action.payload} 
        });
    }
    else if(action.type === SET_FILTER_SHOW){
        // Set new list
        return Object.assign({}, state, {
            filters: {...state.filters, show: action.payload} 
        });
    }
    else if(action.type === SET_FILTER_SORT_BY){
        // Set new list
        return Object.assign({}, state, {
            filters: {...state.filters, sort_by: action.payload} 
        });
    }
    else if(action.type === SET_FILTER_OPEN_ONLY){
        // Set new list
        return Object.assign({}, state, {
            filters: {...state.filters, open_only: action.payload} 
        });
    }


    return state;
};