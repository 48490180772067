import { Box, Button, CircularProgress, Divider, Grid, TextField, Tooltip } from "@mui/material";
import ReviewItem from "../item/ReviewItem";
import ModalLayout from "../lib/ModalLayout";
import styled from 'styled-components'
import { ModalBody, RatingLabel, RatingSlider, TextAreaSmall } from "../../styles/layout";
import { useSelector } from "react-redux";
import { Location } from "../../schemas/locations";
import { faEdit, faPersonWalking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import store from '../../lib/store'
import { setModalEditLocation } from "../../reducers/modals";
import Services from "../item/Services";
import Rating from "../item/Rating";
import { useEffect, useState } from "react";
import { Review } from "../../schemas/review";
import { updateLocationReview } from "../../reducers/locations";
import { LoadingButton } from "@mui/lab";
import moment from 'moment'
import { device } from "../../styles/breakpoints";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useSnackbar } from "notistack";
import OpenMap from "../lib/OpenMap";

interface PropsInterface {
    open: boolean;
    onClose: () => void;
}

const ViewLocation = ({ open, onClose }: PropsInterface) => {
    const { enqueueSnackbar } = useSnackbar();

    const locations = useSelector((state: any) => state.locations)
    const auth = useSelector((state: any) => state.auth)
    const modals = useSelector((state: any) => state.modals)

    const getSelected = () => {
        const index = locations.data.findIndex((loc: Location) => loc.id === modals.selected)

        if (index >= 0)
            return locations.data[index]
        return undefined;
    }

    const getOpenHours = () => {
        if (!getSelected())
            return []

        // Format time
        return getSelected().hours.map((val: string) => {
            let open, closed = ""
            const endOfDayIndex = val.indexOf(" ") + 1
            const day = val.slice(0, endOfDayIndex);

            if (val.includes("Closed"))
                closed = "Closed"
            else {
                // let times = val.slice(endOfDayIndex).split(" – ");

                // open = moment(times[0], 'HH:mm a').format("HH:mm")
                // closed = moment(times[1], 'HH:mm a').format("HH:mm")
                const regex = /([0-9]*:[0-9]*\s\w*)\s–\s([0-9]*:[0-9]*\s\w*)/gm;
                let m = regex.exec(val);
                if (m && m.length == 3) {
                    open = moment(m[1], 'HH:mm a').format('HH:mm');
                    closed = moment(m[2], 'HH:mm a').format('HH:mm');
                }
                else {
                    const hours = val.substring(val.indexOf(" ") + 1).split(" – ");
                    open = moment(hours[1], 'HH:mm a').format('HH:mm');
                    closed = moment(hours[1], 'HH:mm a').format('HH:mm');
                }

            }
            return {
                day, open, closed
            }
        })
    }

    // Post / Update review
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [form, setForm] = useState<any>({
        rating: 3,
        comment: ""
    })

    const handleReviewSubmit = async () => {
        setLoadingSubmit(true);
        const index = reviews.findIndex((r: Review) => r.user.id == auth.userId);

        if (index > -1) {
            // UPDATE
            await fetch(`${process.env.REACT_APP_API_URL}/reviews/${reviews[index].id}`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-type": "application/json"
                    },
                    body: JSON.stringify(form)
                }
            ).then((res) => res.json())
                .then((data) => {
                    if (!data.message) {
                        store.dispatch(updateLocationReview({ id: modals.selected, count: data.count, score: data.score }))
                    }
                })
                .catch((err) => {
                    enqueueSnackbar('Failed to submit review', {
                        variant: "error"
                    });
                })

            // Update review index
            const new_reviews = [...reviews]
            new_reviews[index] = { ...new_reviews[index], ...form }
            setReviews(new_reviews)
        }
        else {
            await fetch(`${process.env.REACT_APP_API_URL}/reviews/`,
                {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json"
                    },
                    body: JSON.stringify({
                        ...form,
                        restId: modals.selected
                    })
                }).then((res) => res.json())
                .then((data) => {
                    if (!data.message) {
                        store.dispatch(updateLocationReview({ id: modals.selected, count: data.count, score: data.score }))
                    }
                })
                .catch((err) => {
                    enqueueSnackbar('Failed to update review', {
                        variant: "error"
                    });
                })

            // Add to reviews
            const new_reviews = [...reviews]
            new_reviews.push({
                ...form,
                id: -1, // TODO: Fix this, return ID
                user: {
                    ...auth.user,
                    id: auth.userId,
                }
            } as Review)
            setReviews(new_reviews)
        }
        setLoadingSubmit(false);
    }

    const [loadingReviews, setLoadingReviews] = useState<boolean>(true);
    const [reviews, setReviews] = useState<Review[]>([])

    const fetchReviews = async () => {
        let review_data: Review[] = []
        await fetch(`${process.env.REACT_APP_API_URL}/reviews/${modals.selected}`,
            {
                method: "GET"
            }
        ).then((res) => res.json())
            .then((data) => {
                if (!data.message) {
                    review_data = data
                    setReviews(data)
                }
            })
            .catch((err) => { })

        // Check if any of the reviews are from the user
        if (review_data) {
            const index = review_data.findIndex((r: Review) => r.user.id == auth.userId)
            if (index > -1) {
                setForm(review_data[index])
            }
        }

        setLoadingReviews(false);
    }

    useEffect(() => {
        if (open) {
            setLoadingReviews(true)
            fetchReviews()
        }
    }, [open])

    return (
        <ModalLayout open={open} onClose={onClose} title={getSelected() ? getSelected().name : ""} width="800px">
            <>
                {
                    getSelected() && (
                        <Grid container columns={8}>
                            <Grid item sm={8} md={5}>
                                <ModalBody>
                                    <Grid container columns={2} spacing={2}>
                                        <Grid item xs={2} sm="auto" sx={{ width: { xs: "100%", sm: "200px" } }}>
                                            { /* Image & Info */}
                                            <Grid container columns={2} spacing={{ xs: 1, sm: 1, md: 0 }}>
                                                <Grid item xs={1} sm={2}>
                                                    <Image src={`${process.env.REACT_APP_API_URL}/places/photo/${getSelected().id}`} />
                                                </Grid>

                                                <Grid item xs={1} sm={2}>
                                                    <Box display={{ xs: "block", sm: "block", md: "none" }}>
                                                        {getSelected().description}
                                                    </Box>
                                                    <Box sx={{ textAlign: "center" }}>
                                                        <Services
                                                            dine_in={getSelected().options ? getSelected().options.includes("Dine-in") : false}
                                                            takeout={getSelected().options ? getSelected().options.includes("Takeout") : false}
                                                            delivery={getSelected().options ? getSelected().options.includes("Delivery") : false}
                                                        />
                                                    </Box>

                                                    <TagsHolder>
                                                        <DistanceTag>{getSelected().distance}</DistanceTag>
                                                        <DistanceTag>
                                                            <FontAwesomeIcon icon={faPersonWalking}></FontAwesomeIcon>
                                                            {getSelected().duration}
                                                        </DistanceTag>

                                                        <Tooltip title={getSelected().discount.comment}>
                                                            <DistanceTag>{getSelected().discount.percentage} %</DistanceTag>
                                                        </Tooltip>
                                                    </TagsHolder>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} sm>
                                            <Divider textAlign="left">
                                                Open hours
                                            </Divider>
                                            <TableHours>
                                                <tbody>
                                                    {
                                                        getOpenHours().map((val: any) => (
                                                            <tr>
                                                                <td>{val.day}</td>
                                                                <td>{val.open}</td>
                                                                <td>{val.closed}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </TableHours>

                                            <OpenMap address={getSelected().address} lat={getSelected().lat} lng={getSelected()?.lng} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            {getSelected().description}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Divider textAlign="left">
                                                Score
                                            </Divider>
                                            <Grid container columns={2} spacing={1} sx={{ mt: 1, mb: 1 }}>
                                                <Grid item xs={2} sm={1}>
                                                    <RatingTitle>Netlight</RatingTitle>
                                                    <Rating rating={getSelected().netlightRating.score} large center />
                                                    <RatingCount>Out of <b>{getSelected()?.netlightRating.count}</b></RatingCount>
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <RatingTitle>Google</RatingTitle>
                                                    <Rating rating={getSelected()?.googleRating.score} large google center />
                                                    <RatingCount>Out of <b>{getSelected()?.googleRating.count}</b></RatingCount>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <Divider textAlign="left">
                                                Good for
                                            </Divider>
                                            <Grid container spacing={1} sx={{ mt: 1 }}>
                                                {
                                                    getSelected().types.map((type: string) => (
                                                        <Grid item xs="auto" key={`tag-${getSelected().id}-${type}`}>
                                                            <Tag light>
                                                                {type}
                                                            </Tag>
                                                        </Grid>
                                                    )
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <Divider textAlign="left">
                                                Food types
                                            </Divider>
                                            <Grid container spacing={1} sx={{ mt: 1 }}>
                                                {
                                                    getSelected().foodTypes && getSelected().foodTypes.map((type: string) => (
                                                        <Grid item xs="auto" key={`tag-${getSelected().id}-${type}`}>
                                                            <Tag light>
                                                                {type}
                                                            </Tag>
                                                        </Grid>
                                                    )
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Box display="flex" sx={{ mt: 2 }} justifyContent="flex-end" alignItems="flex-end">
                                        <Button
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            sx={{ color: "white" }}
                                            startIcon={<FontAwesomeIcon icon={faEdit} />}
                                            onClick={() => store.dispatch(setModalEditLocation({ value: true }))}
                                        >
                                            Edit
                                        </Button>
                                    </Box>

                                    <Box display={{ xs: "block", sm: "none" }}>
                                        <ReviewTitle>Reviews</ReviewTitle>
                                    </Box>
                                </ModalBody>
                            </Grid>

                            <Grid item sm={8} md={3} sx={{ backgroundColor: "#5e5e5e", borderBottomRightRadius: "15px", width: "100%" }}>

                                {
                                    loadingReviews
                                        ? (
                                            <Box sx={{ mt: 1 }} textAlign="center">
                                                <CircularProgress sx={{ color: "white" }} />
                                            </Box>
                                        )
                                        : (
                                            <>
                                                <Grid container columns={2} flexDirection="column" sx={{ height: "100%" }}>
                                                    <Grid item xs>
                                                        <ReviewHolder>
                                                            <TransitionGroup>
                                                                {
                                                                    reviews.map((review: Review, index: number) => (
                                                                        <CSSTransition
                                                                            timeout={400}
                                                                            classNames="fade"
                                                                            key={`review-${index}`}
                                                                        >
                                                                            <ReviewItem key={`review-${index}`} review={review} />
                                                                        </CSSTransition>
                                                                    ))
                                                                }
                                                            </TransitionGroup>
                                                        </ReviewHolder>
                                                    </Grid>

                                                    <Grid item xs="auto">
                                                        <ReviewInputHoler>
                                                            <TextAreaSmall
                                                                multiline
                                                                fullWidth
                                                                rows={3}
                                                                placeholder="Optional review comment..."
                                                                value={form.comment}
                                                                onChange={(e) => setForm({ ...form, comment: e.target.value })}
                                                            />

                                                            <Grid container columns={2} spacing={2} alignItems="center" sx={{ mt: 0 }}>
                                                                <Grid item xs={2} sm>
                                                                    <RatingLabel id="non-linear-slider" gutterBottom>
                                                                        Rating
                                                                    </RatingLabel>
                                                                    <RatingSlider
                                                                        max={5}
                                                                        min={0}
                                                                        valueLabelDisplay="on"
                                                                        step={0.5}
                                                                        value={form.rating}
                                                                        onChange={(_, newValue) => setForm({ ...form, rating: newValue })}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={2} sm="auto">
                                                                    <LoadingButton
                                                                        variant="outlined"
                                                                        sx={{ color: "white" }}
                                                                        onClick={handleReviewSubmit}
                                                                        loading={loadingSubmit}
                                                                    >
                                                                        {
                                                                            reviews.findIndex((r: Review) => r.user.id == auth.userId) > -1
                                                                                ? "Update"
                                                                                : "Submit"
                                                                        }
                                                                    </LoadingButton>
                                                                </Grid>
                                                            </Grid>
                                                        </ReviewInputHoler>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                }
                            </Grid>
                        </Grid>
                    )
                }
            </>
        </ModalLayout>
    )
}

export default ViewLocation;

const ReviewTitle = styled.h2`
    padding: 10px 0 5px 0;
    margin: 0;
    text-align: center;
`

const RatingTitle = styled.h2`
    margin: 0;
    padding: 0 0 5px 0;
    text-align: center;
`

const ReviewInputHoler = styled.div`
    background: #565656;
    padding: 25px 15px;
`;

const ReviewHolder = styled(ModalBody)`
    max-height: 360px;
    overflow-y: auto;
    border-bottom-right-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
`;
const Image = styled.div<{ src: string }>`
    background-image: url(${({ src }) => src});
    background-size: cover;
    height: 175px;
    width: 175px;
    border-radius: 25px;
    display: block;
    margin-bottom: 5px;

    @media ${device.xs}{
        height: 125px;
        width: 125px;
    }
`;

const TableHours = styled.table`
    width: 100%;
    padding: 5px 0;
    font-size: 14px;
    & tr{
        & td{
            padding: 5px 0;
        }
        & td:not(:first-of-type){
            text-align: right;
        }
    }
`

const TagsHolder = styled.div`
    margin-top: 15px;
    text-align: center;
`

const DistanceTag = styled.div`
    display: inline-block;
    font-size: 14px;
    background: #e1e1e1;
    color: #212121;
    padding: 5px;
    border-radius: 5px;
    &:not(:first-of-type){
        margin-left: 10px;
    }
`;

const Tag = styled.div<{ light?: boolean }>`
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    color: ${({ light }) => light ? "#212121" : "white"};
    background: ${({ light }) => light ? "#eaeaea" : "#4D4D4D"};
    font-size: 14px;
`;


const RatingCount = styled.div`
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
`;

const RatingHolder = styled.div`
    background: #828282;
    padding: 10px;
    border-radius: 10px;
`;