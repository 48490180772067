import styled from 'styled-components'
import moment from 'moment'

interface PropsInterface{
    time: string;
}

const ActivityTime = ({time} : PropsInterface) => {

    const getTimeLabel = () => {

        const dateNow = moment(moment().format("yyyy-MM-DD"))
        const date = moment(time)

        const diff = date.diff(dateNow, "days");

        if(diff >= 0 && diff < 7)
            return `Departing at ${time.split(" ")[1]}`
        else if(diff >= 7 && diff < 14)
            return `Departing at ${date.format('dddd').substring(0,3)}, ${time.split(" ")[1]}`
        else
            return `Departing at ${time}`
    }

    return (
        <Holder>
            {getTimeLabel()}
        </Holder>
    )
}

export default ActivityTime;

const Holder = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
`; 