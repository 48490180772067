import { Box, Button, Grid } from "@mui/material";
import styled from "styled-components";
import OpeningHours from "./OpeningHours";
import Rating from "./Rating";
import Services from "./Services";
import { Location } from "../../schemas/locations"
import { useState } from "react";
import { setSelected } from "../../reducers/locations";
import store from '../../lib/store'
import { setModalSelected, setModalViewLocation } from "../../reducers/modals";
import moment from 'moment'
import { device } from '../../styles/breakpoints'
import { DistanceTag } from "../../styles/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonWalking } from "@fortawesome/free-solid-svg-icons";


interface PropsInterface {
    item: Location;
    selected?: boolean;
    id?: string;
}

const ItemCard = ({ item, selected, id }: PropsInterface) => {
    const [hover, setHover] = useState<boolean>(false);

    const handleView = (e: any) => {
        // e.stopPropagation();

        // set selected id
        store.dispatch(setModalSelected(item.id))

        // Open modal
        store.dispatch(setModalViewLocation(true));
    }

    const getClosedOpen = () => {
        let day = Math.abs(new Date().getDay() - 1) // Wrap Sunday to 6th
        let selected_day = "";
        let i = 0;

        // Go through all days in list
        for (i = 0; i < 7; i++) {
            try {
                selected_day = item.hours[(day + i) % 7]
                if (!selected_day.includes("Closed"))
                    break;
            }
            catch (err) {
                continue;
            }
        }
        const regex = /([0-9]*:[0-9]*\s\w*)\s–\s([0-9]*:[0-9]*\s\w*)/gm;

        const str = selected_day.substring(selected_day.indexOf(" ") + 1);
        let m = regex.exec(str);
        if (m && m.length == 3) {
            if (m[2].includes('AM')){
                return {
                    open: moment(m[1], 'HH:mm a').add(i, 'day').toDate(),
                    closed: moment(m[2], 'HH:mm a').add(i + 1, 'day').toDate()
                }
            }
            else {
                return {
                    open: moment(m[1], 'HH:mm a').add(i, 'day').toDate(),
                    closed: moment(m[2], 'HH:mm a').add(i, 'day').toDate()
                }
            }
        }
        else {
            const hours = selected_day.substring(selected_day.indexOf(" ") + 1).split(" – ");
            return {
                open: moment(hours[1], 'HH:mm a').add(i, 'day').toDate(),
                closed: moment(hours[1], 'HH:mm a').add(i, 'day').toDate()
            }        }

    }

    const handleClick = (e: any) => {
        e.stopPropagation();

        if (selected) {
            handleView(e);
        }
        else {
            store.dispatch(setSelected(item.id))
        }
    }

    return (
        <Holder
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={handleClick}
            selected={selected || hover ? 1 : 0}
            id={id}
        >
            <Grid container>
                <Grid item xs>
                    <Title>{item.name}</Title>
                    <Box display="flex" gap="10px" alignItems="center">
                        {item.netlightRating.count == 0 ?
                            <div>No reviews</div> :
                            <Rating rating={item.netlightRating.score} />
                        }
                        <Box display={{ xs: "none", sm: "inline-block" }}>
                            <DistanceTag>
                                {item.distance}
                            </DistanceTag>
                            <DistanceTag>
                                <FontAwesomeIcon icon={faPersonWalking}></FontAwesomeIcon>
                                {item.duration}
                            </DistanceTag>
                        </Box>
                    </Box>

                    <OpacityHolder>
                        <Text>
                            {item.description}
                        </Text>

                        <OpeningHours open={getClosedOpen().open} closed={getClosedOpen().closed} />

                        <Box display={{ xs: "block", sm: "none" }}>
                            <Services
                                dine_in={item.options ? item.options.includes("Dine-in") : false}
                                takeout={item.options ? item.options.includes("Takeout") : false}
                                delivery={item.options ? item.options.includes("Delivery") : false}
                            />
                        </Box>

                        <Box display={{ xs: "none", sm: "block" }}>
                            <Grid container spacing={1} sx={{ mt: 1 }}>
                                {
                                    item.types.map((type: string) => (
                                        <Grid item xs="auto" key={`tag-${item.id}-${type}`}>
                                            <Tag>
                                                {type}
                                            </Tag>
                                        </Grid>
                                    )
                                    )
                                }
                            </Grid>

                            <Grid container spacing={1} sx={{ mt: 1 }}>
                                {
                                    item.foodTypes && item.foodTypes.map((type: string) => (
                                        <Grid item xs="auto" key={`tag-${item.id}-${type}`}>
                                            <Tag light>
                                                {type}
                                            </Tag>
                                        </Grid>
                                    )
                                    )
                                }
                            </Grid>
                        </Box>
                    </OpacityHolder>

                </Grid>
                <Grid item xs="auto">
                    <Box sx={{ textAlign: "right" }}>
                        <Image src={`${process.env.REACT_APP_API_URL}/places/photo/${item.id}`} />
                        <OpacityHolder>
                            <ButtonView
                                color="primary"
                                variant="text"
                                sx={{ marginRight: { xs: "5px" } }}
                            >
                                View Place
                            </ButtonView>
                        </OpacityHolder>

                        <Box display={{ xs: "none", sm: "block" }} sx={{ textAlign: "center" }}>
                            <OpacityHolder>
                                <Services
                                    dine_in={item.options ? item.options.includes("Dine-in") : false}
                                    takeout={item.options ? item.options.includes("Takeout") : false}
                                    delivery={item.options ? item.options.includes("Delivery") : false}
                                />
                            </OpacityHolder>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Holder>
    )
}

export default ItemCard;

const Tag = styled.div<{ light?: boolean }>`
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    color: ${({ light }) => light ? "#212121" : "white"};
    background: ${({ light }) => light ? "#eaeaea" : "#4D4D4D"};
    font-size: 12px;
`;

const Title = styled.h2`
    margin: 5px 0 10px 0;

    @media ${device.sm_down}{
        font-size: 18px;
    }
`;

const Text = styled.p`
    font-size: 14px;
    margin: 10px 0;
`;

const Image = styled.div<{ src: string }>`
    background-image: url(${({ src }) => src});
    background-size: cover;
    height: 75px;
    width: 75px;
    border-radius: 25px;
    margin-left: auto;
    display: block;
    
    transition-property: width, height;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);

    @media ${device.xs}{
        height: 60px;
        width: 60px;
        border-radius: 15px;
    }
`;

const ButtonView = styled(Button)`
    width: 100%;

    @media ${device.xs}{
        width: auto;
    }
`
const OpacityHolder = styled.div``

const Holder = styled.div<{ selected: number }>`
    background: white;
    padding: 25px;
    border-radius: 10px;
    margin-top: 15px;
    cursor: pointer;

    @media ${device.sm_down}{
        padding: 15px;
    }

    & ${OpacityHolder}{
        opacity: 0;
        transition-property: max-height, opacity;
        transition-duration: 0.9s;
        transition-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
        max-height: 0px;
        overflow: hidden;
    }

    ${({ selected }) => selected && `
        & ${Image}{
            height: 125px;
            width: 125px;
            
            @media ${device.xs}{
                height: 75px;
                width: 75px;
            }
        }

        & ${OpacityHolder}{
            opacity: 1;
            max-height: 180px;
        }
    `}
`;
