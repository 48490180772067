import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, TextField, Button, Grid, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import styled from 'styled-components'

interface PropsInterface {
    checkAll?: boolean;
    add?: boolean;
    items: string[];
    selectedItems: string[];
    onAdd?: (search : string) => void;
    onItemChange: (items : string[]) => void;
    disableSearch?: boolean;
    label: string;
    placeholder?: string;
    tooltip?: string;
}

const SearchableSelect = ({checkAll, items, selectedItems, disableSearch, add, label, onAdd, onItemChange, placeholder, tooltip} : PropsInterface) => {
    const [search, setSearch] = useState<string>("")

    return (
        <FormControl sx={{width: "100%"}}>
            <InputLabel shrink id={`${label}-select-label`}>{label}</InputLabel>
            <Select
                size="small"
                labelId={`${label}-select-label`}
                id={`${label}-select`}
                input={<OutlinedInput label={label} />}
                multiple
                value={selectedItems}
                onChange={(e) => {
                    const val = (typeof e.target.value === 'string') ? e.target.value.split(',') : e.target.value;

                    if(val.indexOf("All") > -1){
                        onItemChange(items)
                    }
                    else if(val.indexOf("UncheckAll") > -1){
                        onItemChange && onItemChange([])
                    }
                    else
                        onItemChange(val)
                }}
                renderValue={(selected) => {
                    if(selected.length === items.length)
                        return "All"
                    return selected.join(', ')
                }}
                >
                    {
                        !disableSearch && 
                        (<InputMenuItem onKeyDown={(e) => e.stopPropagation()}>
                            <Grid container alignItems={"center"}>
                                <Grid item xs>
                                    <TextField
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder={placeholder ?? ""}
                                        variant="standard"
                                        fullWidth
                                    />
                                </Grid>
                                {
                                    add && 
                                    (<Grid item xs="auto">
                                        <Tooltip title={tooltip ?? ""}>
                                            <IconButton
                                                color="primary"
                                                onClick={() => onAdd && onAdd(search)}
                                            >
                                                <FontAwesomeIcon icon={faPlusCircle}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>)
                                }
                            </Grid>
                        </InputMenuItem>)
                    }
                
                {
                    checkAll && (
                        selectedItems.length === 0
                        ? (<MenuItem value={"All"}>
                            <Checkbox checked={false} size="small" />
                            <ListItemText primary={"Check all"} />
                        </MenuItem>)
                        : (<MenuItem value={"UncheckAll"}>
                            <Checkbox checked={true} indeterminate={!(selectedItems.length === items.length)} size="small" />
                            <ListItemText primary={"Uncheck all"} />
                        </MenuItem>)
                    )
                }
                
                {
                    items
                    .filter((val : string) => {
                        if(search && val.toLowerCase().indexOf(search.toLowerCase()) > -1 || !search)
                            return true
                        return false
                    })
                    .map((val : string, index: number) => 
                            (<MenuItem value={val} key={`menu-item-${index}`}>
                                <Checkbox checked={selectedItems.indexOf(val) > -1} size="small" />
                                <ListItemText primary={val} />
                            </MenuItem>)
                        )
                }
            </Select>
        </FormControl>
    )
}

export default SearchableSelect;

const InputMenuItem = styled.div`
    padding: 10px 20px;
`