import { faPlusCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, CircularProgress, Divider, Grid, InputAdornment,TextField, Switch, FormGroup, FormControlLabel } from "@mui/material";
import { useSnackbar } from "notistack";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { addFoodType, addLocation, DefaultTypes, setSelected, updateLocationReview } from "../../reducers/locations";
import store from "../../lib/store";
import { ModalBody, RatingLabel, HeartRating } from "../../styles/layout";
import ModalLayout from "../lib/ModalLayout";
import { Location } from '../../schemas/locations'
import SearchableSelect from "../lib/SearchableSelect";
import TypeButtons from "../lib/TypeButtons";
import Discount from "../lib/Discount";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteIconBorderIcon from '@mui/icons-material/FavoriteBorder';

interface PropsInterface {
    open: boolean;
    onClose: () => void;
}

const CreateLocation = ({ open, onClose }: PropsInterface) => {
    const { enqueueSnackbar } = useSnackbar();

    const locations = useSelector((state: any) => state.locations);

    // Form data
    const [form, setForm] = useState<any>({
        type: DefaultTypes,
        discount: {
            percentage: 0,
            comment: ""
        },
        description: "",
        foodTypes: [],
        review: {
            rating: 0,
            comment: ""
        },
        googleId: "",
        includeReview: false,
    })

    // Clear form when modal is opened/closed
    useEffect(() => {
        (async () => {
            // Reset
            setForm({
                type: DefaultTypes,
                discount: {
                    percentage: 0,
                    comment: ""
                },
                description: "",
                foodTypes: [],
                review: {
                    rating: 0,
                    comment: ""
                },
                googleId: "",
                includeReview: false,
            })

            setInputvalue("");

            // Perform search is locations search is set
            if (locations.search)
                await performSearch(locations.search, true)
        })()
    }, [open])

    // Validation
    const validate = () => {
        // Check if locatiion is selected
        if (!form.googleId) {
            enqueueSnackbar('No location selected', { variant: "error" })
            return false;
        }

        // check if type is selected
        if (form.type.length === 0) {
            enqueueSnackbar('Must specify what it\'s good for', { variant: "error" })
            return false;
        }

        // check if food type is selected
        if (form.foodTypes.length === 0) {
            enqueueSnackbar('No food type selected', { variant: "error" })
            return false;
        }

        return true;
    }

    // Handle add
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const handleAdd = async () => {
        if (!validate())
            return;

        setButtonLoading(true);

        // Add to location
        let id: number = -1;

        await fetch(`${process.env.REACT_APP_API_URL}/locations/`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify(form)
            }
        ).then((res) => res.json())
            .then((data) => {
                if (data.message) {
                    enqueueSnackbar('Failed to add place', {
                        variant: "error"
                    });
                }
                else {
                    enqueueSnackbar(`Succesfully added ${data.name}`, {
                        variant: "success"
                    });

                    // Add location
                    store.dispatch(addLocation(data as Location))
                    id = data.id;

                    // Set location as selected
                    store.dispatch(setSelected(id))
                }
            })
            .catch((err) => {
                enqueueSnackbar('Failed to add place', {
                    variant: "error"
                });
            })

        // Add review
        if (form.includeReview && id >= 0) {
            await fetch(`${process.env.REACT_APP_API_URL}/reviews/`,
                {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json"
                    },
                    body: JSON.stringify({
                        ...form.review,
                        restId: id
                    })
                }
            ).then((res) => res.json())
                .then((data) => {
                    // Add data to location score, count
                    if (!data.error) {
                        store.dispatch(updateLocationReview({ id: id, count: data.count, score: data.score }))
                    }

                })
                .catch((err) => { })

            onClose()
        }
        else if (!form.includeReview) {
            //Close modal if no review
            onClose();
        }

        setButtonLoading(false);
    }

    // Selected 
    const handleSelect = (selected: any) => {
        if (selected.place_id)
            setForm({ ...form, googleId: selected.place_id })
    }

    // For handling auto search
    const [result, setResult] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [autoOpen, setAutoOpen] = useState<boolean>(false);
    const [inputValue, setInputvalue] = useState<string>("");

    const timer = useRef<any>(null);

    const handleAutoLocations = async (e: any) => {
        setInputvalue(e.target.value)

        await performSearch(e.target.value)
    };

    const performSearch = async (search: string, changeSelect?: boolean) => {
        // Timeout
        if (timer.current)
            clearTimeout(timer.current);

        setLoading(true);
        timer.current = setTimeout(async () => {
            await fetch(`${process.env.REACT_APP_API_URL}/places/query/${search}`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json"
                    }
                }
            ).then((res) => res.json())
                .then((data) => {
                    if (data.result.predictions) {
                        setResult(data.result.predictions)

                        // Change selected
                        if (changeSelect) {
                            setInputvalue(data.result.predictions[0].description)
                            handleSelect(data.result.predictions[0])
                        }
                    }
                    else
                        setResult([])
                })
                .catch((err) => { })
            setLoading(false);
        }, 400)
    }

    // Creating food type
    const handleAddFoodType = async (name: string) => {
        await fetch(`${process.env.REACT_APP_API_URL}/food_types/`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify({ name })
            }
        ).then((res) => res.json())
            .then((data) => {
                if (data.message) {
                    enqueueSnackbar('Food type already exists', {
                        variant: "error"
                    });
                    return;
                }

                enqueueSnackbar(`Food type added`, {
                    variant: "success"
                });
                store.dispatch(addFoodType(name))
                setForm({ ...form, foodTypes: [...form.foodTypes, name] })
            })
            .catch((err) => {
                enqueueSnackbar('Failed to add food type', {
                    variant: "error"
                });
            })
    }

    const switchReview = () => {
        const tmp = !form.includeReview
        setForm({ ...form, includeReview: tmp })
    }

    // Handle changes from types component
    const onTypeChange = (item: any) => {
        setForm({...form, type: item})
    }

    return (
        <ModalLayout open={open} onClose={onClose} title="Add Place" width="450px">
            <ModalBody>
                <Grid container columns={2} spacing={2}>
                    <Grid item xs={2}>
                        <Divider textAlign="left">Place info</Divider>
                    </Grid>
                    <Grid item xs={2}>
                        <Autocomplete
                            inputValue={inputValue}
                            open={autoOpen}
                            onOpen={() => {
                                setAutoOpen(true);
                            }}
                            onClose={() => {
                                setAutoOpen(false);
                            }}
                            onChange={(e: FormEvent, reason: any) => {
                                if (reason) {
                                    setInputvalue(reason.description)
                                    handleSelect(reason)
                                }
                            }}
                            noOptionsText={"Nothing found..."}
                            clearIcon={<></>}
                            popupIcon={<></>}
                            getOptionLabel={(option: any) => option.description}
                            options={result}
                            loadingText="Searching..."
                            loading={loading}
                            renderInput={(params) => (
                                <TextField
                                    placeholder="Enter place name"
                                    {...params}
                                    InputLabelProps={{ shrink: true }}
                                    variant={"outlined"}
                                    label={"* Search place"}
                                    onChange={handleAutoLocations}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {loading
                                                    ? <CircularProgress color="inherit" size={20} />
                                                    : <FontAwesomeIcon icon={faSearch} />
                                                }
                                                {params.InputProps.startAdornment}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs sm={2}>
                        <TypeButtons
                            items={DefaultTypes}
                            changeType={onTypeChange}
                        ></TypeButtons>
                    </Grid>

                    <Grid item xs={2} sm={1}>
                        {/* <SearchableSelect
                            label="* Good for"
                            items={DefaultTypes}
                            selectedItems={form.type}
                            onItemChange={(items) => store.dispatch(setForm({ ...form, type: items }))}
                            checkAll
                            disableSearch
                        /> */}
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <SearchableSelect
                            label="* Food"
                            items={locations.food_types}
                            selectedItems={form.foodTypes}
                            onItemChange={(items) => setForm({ ...form, foodTypes: items })}
                            add
                            onAdd={handleAddFoodType}
                            tooltip="Add foodtype"
                            placeholder="Search/add foodtype..."
                        />
                    </Grid>


                    <Grid item xs={2}>
                        <TextField
                            label="Description"
                            InputLabelProps={{ shrink: true }}
                            multiline
                            fullWidth
                            placeholder="Description of place"
                            rows={2}
                            value={form.description}
                            onChange={(e) => setForm({ ...form, description: e.target.value })}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Divider textAlign="left">Discount</Divider>
                    </Grid>

                    <Grid item xs={2} sm={1}>
                        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                            <Discount
                                value={form.discount.percentage}
                                onChange={(val) => {
                                    setForm({ ...form, discount: { ...form.discount, percentage: Number(val) } })
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={2} sm={1}>
                        <TextField
                            label="Discount note"
                            InputLabelProps={{ shrink: true }}
                            multiline
                            fullWidth
                            placeholder="Instructions for applying discount"
                            rows={2}
                            value={form.discount.comment}
                            onChange={(e) => setForm({ ...form, discount: { ...form.discount, comment: e.target.value } })}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        {/* <Divider textAlign="left">Your review</Divider>
                        <div>
                            Include review <Switch value={form.includeReview}></Switch>
                        </div> */}
                        <Divider textAlign="left">
                            Review
                        </Divider>
                        <FormGroup>
                            <FormControlLabel control={<Switch value={form.includeReview} onChange={switchReview} ></Switch>} label='Include review'></FormControlLabel>
                        </FormGroup>
                    </Grid>


                    <>
                        <Grid item xs={2} sm={1}>
                            <RatingLabel id="non-linear-slider" gutterBottom>
                                Rating
                            </RatingLabel>
                            {/* <RatingSlider
                                    value={form.review.rating}
                                    onChange={(_, newValue) => setForm({ ...form, review: { ...form.review, rating: Number(newValue) } })}
                                    max={5}
                                    min={0}
                                    valueLabelDisplay="auto"
                                    step={0.5}
                                    disabled={!form.includeReview}
                                /> */}
                            <HeartRating
                                precision={0.5}
                                name="customized-color"
                                icon={<FavoriteIcon fontSize="inherit" />}
                                emptyIcon={<FavoriteIconBorderIcon></FavoriteIconBorderIcon>}
                                onChange={(_, newValue) => setForm({ ...form, review: { ...form.review, rating: Number(newValue) } })}
                                disabled={!form.includeReview}
                            ></HeartRating>
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <TextField
                                label="Review comment"
                                placeholder="Review comment"
                                InputLabelProps={{ shrink: true }}
                                multiline
                                fullWidth
                                rows={2}
                                value={form.review.comment}
                                onChange={(e) => setForm({ ...form, review: { ...form.review, comment: e.target.value } })}
                                disabled={!form.includeReview}
                            />
                        </Grid>
                    </>
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                            <LoadingButton
                                loading={buttonLoading}
                                variant="contained"
                                size="large"
                                sx={{ color: "white" }}
                                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                                onClick={handleAdd}
                                loadingPosition="start"
                            >
                                Add
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>


            </ModalBody >
        </ModalLayout >
    )
}

export default CreateLocation;