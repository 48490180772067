import { Box, Button} from "@mui/material";
import ModalLayout from "../lib/ModalLayout";
import styled from 'styled-components'
import { ModalBody } from "../../styles/layout";
import { useSelector } from "react-redux";
import { Location } from "../../schemas/locations";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useState } from "react";
import store from "../../lib/store";
import { removeLocation } from "../../reducers/locations";
import { Activity } from "../../schemas/activity";
import { removeActivity } from "../../reducers/activity";

interface PropsInterface{
    open: boolean;
    onClose: (fallback: boolean) => void;
}

const DeleteActivity = ({open, onClose} : PropsInterface) => {
    const activities = useSelector((state : any) => state.activities)
    const modals = useSelector((state : any) => state.modals)

    const getSelected = () => {
        const index = activities.data.findIndex((loc : Activity) => loc.eventID === modals.selected_activity)

        if(index >= 0)
            return activities.data[index]
        return null;
    }

    // Snackbar
    const { enqueueSnackbar } = useSnackbar();

    // Handle delete
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const handleDelete = async () => {
        setButtonLoading(true);

        await fetch(`${process.env.REACT_APP_API_URL}/lunch-train/${modals.selected_activity}`,
            {
                method: "DELETE"
            }
        ).then((res) => res.json())
        .then((data) => {
            enqueueSnackbar(`Successfully deleted lunch train`, {
                variant: "success"
            });

            // Change this
            store.dispatch(removeActivity(modals.selected_activity))

            onClose(false)
        })
        .catch((err) => {
            enqueueSnackbar(`Failed to delete lunch train`, {
                variant: "error"
            });
        })

        setButtonLoading(false);
    }

    return (
        <ModalLayout open={open} onClose={() => onClose(true)} title={"Delete Lunch train"} width="400px">
            <ModalBody>
                {
                    getSelected() && (
                        <>
                            <Text>
                                Are you sure you want to delete your lunch train at: {getSelected().time}?
                            </Text>
                            <Box display="flex" gap={1} justifyContent="flex-end" alignItems="flex-end">
                                <Button 
                                    variant="outlined" 
                                    size="large" 
                                    color="primary"
                                    sx={{color: "white"}} 
                                    onClick={() => onClose(true)}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton 
                                    variant="contained" 
                                    size="large" 
                                    color={"error"}
                                    sx={{color: "white"}} 
                                    onClick={handleDelete}
                                    loading={buttonLoading}
                                >
                                    Delete
                                </LoadingButton>
                            </Box>
                        </>
                    )
                }
                
            </ModalBody>
        </ModalLayout>
    )
}

export default DeleteActivity;

const Text = styled.p`
    margin: 0 0 25px 0;
`