import { User } from "../schemas/user";

// Schema
interface stateInterface{
    users: User[];
}

const initialState : stateInterface = {
    users: []
};

// Actions
const ADD_USER = "ADD_USER";

export const addUser = (payload : string | null) => {
    return { type: ADD_USER, payload};
}

export const usersReducer = (state = initialState, action : any) => {
    if(action.type === ADD_USER){
        const new_data = [...state.users]
        new_data.push(action.payload)

        // Set new list
        return Object.assign({}, state, {
            users: new_data
        });
    }

    return state;
};