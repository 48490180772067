import { LocalizationProvider, DatePicker } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import styled from 'styled-components'

interface PropsInterface{
    onUpdate: (dateString : string) => void;
}

export interface DateTimeForm{
    time: string;
    date: Date | null;
}

const getTimeNow = () => {
    const date = new Date().toLocaleTimeString().split(":")
    return `${date[0]}:${date[1]}`
}

const DateTime = ({onUpdate} : PropsInterface) => {
    const [form, setForm] = useState<DateTimeForm>({
        time: getTimeNow(),
        date: new Date()
    })

    useEffect(() => {
        if(form.date)
        try {
            onUpdate(`${form.date.toISOString().split('T')[0]} ${form.time}`)

        } catch (error) {       
            console.error(error);
        }
    }, [form])

    return (
        <>
            <Grid item xs="auto">
                <TimeField
                    value={form.time}
                    onChange={(e) => setForm({...form, time: e.target.value})}
                    type="time"
                />
            </Grid>
            <Grid item xs>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={form.date}
                        onChange={(newValue) => {
                            setForm({...form, date: newValue});
                        }}
                        disablePast
                        inputFormat="YYYY-MM-DD"
                        mask="____-__-__"
                        renderInput={(params) => <TextField {...params} fullWidth InputLabelProps={{shrink: false}} />}
                    />
                </LocalizationProvider>
            </Grid>
        </>
    )
}

export default DateTime;

const TimeField = styled(TextField)`
    width: 115px;
    & *{
        text-align: center;
    }
`