import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components'
import SignInSlack from '../components/slack/SignInSlack';
import { setAuthToken, setRefreshToken, setUserId } from '../reducers/auth';
import store from '../lib/store';

function Login(){
    // Params
    const params = new URLSearchParams(useLocation().search);
    const code = params.get("code");
    const state = params.get("state");

    const [loading, setLoading] = useState(false);
    // Check if params, login if needed
    const getToken = async () => {
        if (!code) {
            // TODO: display error to the user
            console.log("could not retrieve code from the login request")
            return
        }
        setLoading(true);
        await fetch(`${process.env.REACT_APP_API_URL}/auth/callback`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json" 
                },
                body: JSON.stringify({
                    code: code,
                    state: state
                })
            }
        )
        .then((res) => res.json())
        .then((data) => {
            
            // Failed to auth
            if(data.error){
                return;
            }

            // Set auth
            store.dispatch(setAuthToken(data.token))
            store.dispatch(setRefreshToken(data.refresh_token))
            store.dispatch(setUserId(data.id))
        })
        .catch((err) => {
            console.log("error while fetching data")
            console.log(err)
        })

        setLoading(false);
        
    }

    useEffect(() => {        
        getToken()
    }, []);
    
    return (
        <CenterLogin>
            <Logo src={process.env.PUBLIC_URL + "/imgs/logo.png"}/>
            <LoginHolder>
                {
                    loading
                    ? (<CircularProgress sx={{color: "white"}} />)
                    : (<SignInSlack/>)
                }
            </LoginHolder>
        </CenterLogin>
    )
}

export default Login;

const LoginHolder = styled.div`
    background: #A7A2CF;
    padding: 25px;
    display: inline-block;
    border-radius: 20px;
`

const CenterLogin = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
`

const Logo = styled.img`
    width: 200px;
    display: block;
    margin: auto;
`