import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Modal} from "@mui/material";
import styled from 'styled-components'
import { device } from "../../styles/breakpoints";

interface PropsInterface{
    open: boolean;
    onClose: () => void;
    title: string;
    children: JSX.Element;
    width: string;
}

const ModalLayout = ({open, onClose, title, children, width} : PropsInterface) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{
                outline: 0
            }}
        >
            <ModalPadding width={width}>
                <ModalHolder>
                    <CloseButton>
                        <FontAwesomeIcon icon={faTimesCircle} onClick={onClose} size='2x' />
                    </CloseButton>
                        <ModalTitle>{title}</ModalTitle>
                        <ModalBodyHolder>
                            {children}
                        </ModalBodyHolder>
                </ModalHolder>
            </ModalPadding>
        </Modal>
    )
}

export default ModalLayout;

const ModalBodyHolder = styled.div`
    max-height: calc(100vh - 100px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 100px);
    overflow-y: auto;
`

const CloseButton = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    color: white;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.25s linear 0s;

    &:hover{
        opacity: 0.8;
    }
`;


const ModalPadding = styled.div<{width: string}>`
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    max-width: ${({width}) => width};
    width: 100%;
    box-sizing: border-box;
    outline: 0;
`

const ModalHolder = styled.div`
    background: #4d4d4d;
    width: 100%;
    border-radius: 15px;
    color: white;
    position: relative;
    box-shadow: 0 0 10px 0px rgb(0 0 0 / 50%);
    overflow: hidden;
`;

const ModalTitle = styled.div`
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: #373737;
    padding: 25px;
    font-size: 24px;

    @media ${device.sm_down}{
        font-size: 18px;
        padding: 20px;
    }
`;