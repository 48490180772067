import { faLocationDot, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import { breakpoint, device } from '../styles/breakpoints';

interface PropsInterface {
    onClick: (page : number) => void;
}

const Navigation = ({onClick} : PropsInterface) => {
    const modals = useSelector((state : any) => state.modals)
    
    const isInMobile = () => {
        let width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
        return width <= breakpoint.md;
    }

    return (
        <Holder>
            <Grid container alignItems={"center"} padding={1}>
                <Grid item xs>
                    <Logo src={process.env.PUBLIC_URL + "/imgs/logo.png"}/>
                </Grid>
                <Grid item xs="auto">
                    <ButtonNav 
                        active={modals.nav_page === 0 && (isInMobile() && modals.drawer || !isInMobile()) ? 1 : 0}
                        onClick={() => onClick(0)} 
                        variant="text" 
                        size="small" sx={{color: "white"}} startIcon={<FontAwesomeIcon icon={faLocationDot} />}>
                        Places
                    </ButtonNav>

                    <ButtonNav 
                        active={modals.nav_page === 1 && (isInMobile() && modals.drawer || !isInMobile()) ? 1 : 0}
                        onClick={() => onClick(1)}
                        variant="text" size="small" sx={{color: "white"}} startIcon={<FontAwesomeIcon icon={faUtensils} />}>
                        Lunches
                    </ButtonNav>
                </Grid>
            </Grid>
        </Holder>
    )
}

export default Navigation;

const ButtonNav = styled(Button)<{active: number}>`
    padding: 10px;
    ${({active}) => active && `
        &:hover{
            background: rgba(255,255,255, 0.3);
        }
        background: rgba(255,255,255, 0.2);
    `}
`;

const Holder = styled.div`
    background: #373737;
    color: white;

    @media ${device.sm_down}{
        width: 100%;
        position: absolute; 
        z-index: 999;
    }
`;

const Logo = styled.img`
    width: 150px;
    display: block;

    @media ${device.xs}{
        width: 125px;
    }
`