// Schema
interface stateInterface{
    token: string | null;
    refreshToken: string | null;
    userId: number | null;
    user: any;
}

const initialState : stateInterface = {
    token: null,
    refreshToken: null,
    userId: null,
    user: {}
};

// Actions
const SET_TOKEN = "SET_TOKEN";
const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
const SET_USER_ID = "SET_USER_ID";
const SET_USER = "SET_USER";

export const setAuthToken = (payload : string | null) => {
    return { type: SET_TOKEN, payload};
}

export const setRefreshToken = (payload : string | null) => {
    return { type: SET_REFRESH_TOKEN, payload};
}

export const setUserId = (payload : number | null) => {
    return { type: SET_USER_ID, payload};
}

export const setUser = (payload : any) => {
    return { type: SET_USER, payload};
}


export const authReducer = (state = initialState, action : any) => {
    if(action.type === SET_TOKEN){
        // Set new list
        return Object.assign({}, state, {
            token: action.payload
          });
    }
    else if(action.type === SET_REFRESH_TOKEN){
        // Set new list
        return Object.assign({}, state, {
            refreshToken: action.payload
          });
    }
    else if(action.type === SET_USER_ID){
        // Set new list
        return Object.assign({}, state, {
            userId: action.payload
          });
    }
    else if(action.type === SET_USER){
        // Set new list
        return Object.assign({}, state, {
            user: action.payload
          });
    }

    return state;
};