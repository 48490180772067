import { createStore } from "redux";
import rootReducer from "../reducers";
import Cookies from "universal-cookie";
const cookies = new Cookies();

// Create local storage handlers
const saveToLocalStorage = (state : any) => {
    try {
        // Set token in local storage
        const token = state.auth.token
        const userId = state.auth.userId
        const refreshToken = state.auth.refreshToken

        localStorage.setItem("accessToken", token)
        localStorage.setItem("userId", userId)
        localStorage.setItem("refreshToken", refreshToken)
    }
    catch(e){
        console.warn(e)
    }
}

const loadFromLocalStorage = () => {
    try {
        // Get from cookies
        let refreshToken = cookies.get("refreshToken");
        let userId = cookies.get("userId");

        // Get token from local storage
        let token = localStorage.getItem("accessToken");
        if(refreshToken === "null" || !refreshToken)
            refreshToken = localStorage.getItem("refreshToken");
        if(userId === "null" || !userId)
            userId = localStorage.getItem("userId");

        if(token === "null")
            token = null;

        if(refreshToken === "null")
            refreshToken = null;

        if(userId === "null")
            userId = null;

        return {
            auth: {
                token: token,
                refreshToken: refreshToken ?? null,
                userId: userId ?? null,
                user: null
            }
        };
    }
    catch(e){}
    return {
        auth: {
            token: null,
            refreshToken: null,
            userId: null,
            user: null
        }
    };
}

// Create store
const store = createStore(rootReducer, loadFromLocalStorage())

store.subscribe(() => saveToLocalStorage(store.getState()))

export default store;