import { faCog, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import { LogoutUser } from '../lib/auth'
import { device } from '../styles/breakpoints';

const Avatar = () => {
    const auth = useSelector((state : any) => state.auth)
    
    const handleLogout = (e: any) => {
        e.preventDefault();
        LogoutUser();
    }

    return (
        <Holder>
            <Image src={auth.user.avatar} />
            <DropDown>
                <DropDownItem>
                    { auth.user.name }
                </DropDownItem>
                <ButtonNav 
                    fullWidth
                    onClick={handleLogout} 
                    variant="text" 
                    size="small" 
                    sx={{color: "white"}} 
                    startIcon={<FontAwesomeIcon icon={faRightFromBracket} />}
                >
                    Logout
                </ButtonNav>
            </DropDown>
        </Holder>
    )
}

export default Avatar;

const ButtonNav = styled(Button)`
    padding: 10px 25px;
    font-size: 12px;

    &:hover{
        background: rgba(255,255,255, 0.3);
    }

    & svg{
        font-size: 14px!important;
    }
`;

const DropDownItem = styled.div`
    padding: 10px;
    border-bottom: 1px solid grey;
`;

const DropDown = styled.div`
    overflow: hidden;
    background: #4d4d4d;
    border-radius: 10px;
    color: white;
    position: absolute;
    right: 15px;
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.5);
    display: none;
    font-size: 14px;
    width: 150px;
    & ${DropDownItem}:not(:last-of-type){
        border-bottom: 1px solid #373737;
    }

    @media ${device.sm_down}{
        right: auto;
        left: 64px;
        bottom: 0;
    }
`;

const Holder = styled.div`
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 80;
    &:hover > ${DropDown}{
        display: block;
    }

    @media ${device.sm_down}{
        right: auto;
        top: auto;
        bottom: 15px;
        left: 15px;
    }
`;

const Image = styled.div<{src: string}>`
    background-image: url(${({src}) => src});
    background-size: cover;
    height: 75px;
    width: 75px;
    border-radius: 100%;
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.5);

    @media ${device.xs}{
        height: 64px;
        width: 64px;
    }
`