import { useEffect, useRef } from "react";
import { addActivity, editActivity, joinActivity, leaveActivity, removeActivity } from "../reducers/activity";
import { addFoodType, addLocation, editLocation, removeLocation, updateLocationReview } from "../reducers/locations";
import { SSEMessage } from "../schemas/sse";
import store from "./store";

// Create event handler
const useSSE = () => {
    const es = useRef<any>(null)

    // Disconnect on unmount
    useEffect(() => {
        // Try to connect to Eventsource
        es.current = new EventSource(`${process.env.REACT_APP_SSE_URL}/subscribe?token=${store.getState().auth.token}`);

        // Connect to handler
        es.current.onmessage = (event : any) => {
            handler(JSON.parse(event.data) as SSEMessage)
        };

        return (() => {
            es.current.close()
        })
    }, [])

    return es.current
}

export default useSSE;

//--- Handlers ---//
// Main handler
const handler = (msg: SSEMessage) => {
    switch(msg.type){
        case "LOCATION":
            locationHandler(msg)
            break;
        case "LUNCHTRAIN":
            lunchtrainHandler(msg)
            break;
        case "REVIEW":
            reviewHandler(msg)
            break;
        case "FOOD_TYPES":
            foodTypesHandler(msg)
            break;

        // Skip undefined
        default:
            break;
    }
}

// Location handlers
const locationHandler = (msg: SSEMessage) => {
    switch(msg.action){
        case "INSERT":
            store.dispatch(addLocation(msg.payload))
            break;
        case "UPDATE":
            store.dispatch(editLocation({id: msg.payload.id, data: msg.payload}))
            break;
        case "DELETE":
            store.dispatch(removeLocation(msg.payload))
            break;

        default:
            break;
    }
}

// Location handlers
const lunchtrainHandler = (msg: SSEMessage) => {
    switch(msg.action){
        case "INSERT":
            store.dispatch(addActivity(msg.payload))
            break;
        case "UPDATE":
            store.dispatch(editActivity({id: msg.payload.id, data: msg.payload}))
            break;
        case "DELETE":
            store.dispatch(removeActivity(msg.payload))
            break;
        case "JOIN":
            store.dispatch(joinActivity(msg.payload))
            break;
        case "LEAVE":
            store.dispatch(leaveActivity(msg.payload))
            break;

        default:
            break;
    }
}

// Review handler
// TODO: Make it possible to see reviews appear live when someone is in "view"
const reviewHandler = (msg: SSEMessage) => {
    switch(msg.action){
        case "INSERT":
            store.dispatch(updateLocationReview(msg.payload))
            break;
        case "UPDATE":
            store.dispatch(updateLocationReview(msg.payload))
            break;

        default:
            break;
    }
}

// Foodtype handler
const foodTypesHandler = (msg : SSEMessage) => {
    switch(msg.action){
        case "INSERT":
            store.dispatch(addFoodType(msg.payload))
            break;
        default:
            break;
    }
}