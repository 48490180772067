import { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import RequireAuth from './middleware/auth';
import Login from './pages/Login';
import Main from './pages/Main';

function App() {
  const location = useLocation();

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, [])

  return (
    <>
      <Routes location={location}>
        {/* <Route path="/login" element={<RequireAuth component={<Navigate to="/" />} fail={<Login/>} />} /> */}
        <Route path="/login" element={<RequireAuth component={<Navigate to="/" />} fail={<Login/>} />} />
        
        <Route path="/*" element={<RequireAuth component={<Main/>} fail={<Navigate to="/login" />} />} />
      </Routes>
    </>
  );
}

export default App;