// Schema
interface stateInterface{
    delete_location: boolean;
    create_location: boolean;
    edit_location: boolean;
    view_location: boolean;
    create_activity: boolean;
    view_activity: boolean;
    edit_activity: boolean;
    delete_activity: boolean;


    drawer: boolean;
    nav_page: number;

    selected: number;
    selected_activity: number;
}

const initialState : stateInterface = {
    delete_location: false,
    create_location: false,
    edit_location: false,
    view_location: false,
    create_activity: false,
    view_activity: false,
    edit_activity: false,
    delete_activity: false,

    drawer: false,
    nav_page: 0,

    selected: -1,
    selected_activity: -1
};

// Interface value with fallback
interface FallbackValue {
    value: boolean;
    fallback?: boolean;
}

// Actions
const SET_MODAL_CREATE_LOCATION = "SET_MODAL_CREATE_LOCATION";
const SET_MODAL_EDIT_LOCATION = "SET_MODAL_EDIT_LOCATION";
const SET_MODAL_DELETE_LOCATION = "SET_MODAL_DELETE_LOCATION";
const SET_MODAL_VIEW_LOCATION = "SET_MODAL_VIEW_LOCATION";


const SET_MODAL_CREATE_ACTIVITY = "SET_MODAL_CREATE_ACTIVITY";
const SET_MODAL_VIEW_ACTIVITY = "SET_MODAL_VIEW_ACTIVITY";
const SET_MODAL_DELETE_ACTIVITY = "SET_MODAL_DELETE_ACTIVITY";
const SET_MODAL_EDIT_ACTIVITY = "SET_MODAL_EDIT_ACTIVITY";

const SET_DRAWER = "SET_DRAWER";
const SET_NAV_PAGE = "SET_NAV_PAGE";

const SET_MODAL_SELECTED = "SET_MODAL_SELECTED";
const SET_MODAL_SELECTED_ACTIVITY = "SET_MODAL_SELECTED_ACTIVITY";

export const setModalCreateLocation = (payload : boolean) => {
    return { type: SET_MODAL_CREATE_LOCATION, payload};
}

export const setModalEditLocation = (payload : FallbackValue) => {
    return { type: SET_MODAL_EDIT_LOCATION, payload};
}

export const setModalDeleteLocation = (payload : FallbackValue) => {
    return { type: SET_MODAL_DELETE_LOCATION, payload};
}

export const setModalViewLocation = (payload : boolean) => {
    return { type: SET_MODAL_VIEW_LOCATION, payload};
}

export const setModalCreateActivity = (payload : boolean) => {
    return { type: SET_MODAL_CREATE_ACTIVITY, payload};
}

export const setModalViewActivity = (payload : boolean) => {
    return {type: SET_MODAL_VIEW_ACTIVITY, payload};
}

export const setModalEditActivity = (payload : FallbackValue) => {
    return { type: SET_MODAL_EDIT_ACTIVITY, payload};
}

export const setModalDeleteActivity = (payload : FallbackValue) => {
    return { type: SET_MODAL_DELETE_ACTIVITY, payload};
}

export const setDrawer = (payload : boolean) => {
    return { type: SET_DRAWER, payload};
}

export const setNavPage = (payload : number) => {
    return { type: SET_NAV_PAGE, payload};
}

export const setModalSelected = (payload : number) => {
    return { type: SET_MODAL_SELECTED, payload};
}

export const setModalSelectedActivity = (payload : number) => {
    return { type: SET_MODAL_SELECTED_ACTIVITY, payload};
}

export const modalsReducer = (state = initialState, action : any) => {
    if(action.type === SET_MODAL_CREATE_LOCATION){
        // Set new list
        return Object.assign({}, state, {
            create_location: action.payload,
            edit_location: false,
            delete_location: false,
            view_location: false,
            create_activity: false,
            view_activity: false,
        });
    }
    else if(action.type === SET_MODAL_EDIT_LOCATION){
        // Set new list
        if(action.payload.fallback && !action.payload.value)
            return Object.assign({}, state, {
                create_location: false,
                edit_location: false,
                delete_location: false,
                view_location: true,
                create_activity: false,
                view_activity: false,
                edit_activity: false,
                delete_activity: false
            });
        else
            return Object.assign({}, state, {
                create_location: false,
                edit_location: action.payload.value,
                delete_location: false,
                view_location: false,
                create_activity: false,
                view_activity: false,
                edit_activity: false,
                delete_activity: false
            });
    }
    else if(action.type === SET_MODAL_DELETE_LOCATION){
        // Set new list
        if(action.payload.fallback && !action.payload.value)
            return Object.assign({}, state, {
                create_location: false,
                edit_location: true,
                delete_location: false,
                view_location: false,
                create_activity: false,
                view_activity: false,
                edit_activity: false,
                delete_activity: false
            });
        else
            return Object.assign({}, state, {
                create_location: false,
                edit_location: false,
                delete_location: action.payload.value,
                view_location: false,
                create_activity: false,
                view_activity: false,
                edit_activity: false,
                delete_activity: false
            });
    }
    else if(action.type === SET_MODAL_VIEW_LOCATION){
        // Set new list
        return Object.assign({}, state, {
            create_location: false,
            edit_location: false,
            delete_location: false,
            view_location: action.payload,
            create_activity: false,
            view_activity: false,
            edit_activity: false,
            delete_activity: false
        });
    }
    else if(action.type === SET_MODAL_CREATE_ACTIVITY){
        // Set new list
        return Object.assign({}, state, {
            create_location: false,
            edit_location: false,
            delete_location: false,
            view_location: false,
            create_activity: action.payload,
            view_activity: false,
            edit_activity: false,
            delete_activity: false
        });
    }
    else if(action.type === SET_MODAL_VIEW_ACTIVITY){
        // Set new list
        return Object.assign({}, state, {
            create_location: false,
            edit_location: false,
            delete_location: false,
            view_location: false,
            create_activity: false,
            view_activity: action.payload,
            edit_activity: false,
            delete_activity: false
        });
    }

    else if(action.type === SET_MODAL_EDIT_ACTIVITY){
        // Set new list
        if(action.payload.fallback && !action.payload.value)
            return Object.assign({}, state, {
                create_location: false,
                edit_location: false,
                delete_location: false,
                view_location: false,
                create_activity: false,
                view_activity: true,
                edit_activity: false,
                delete_activity: false
            });
        else
            return Object.assign({}, state, {
                create_location: false,
                edit_location: false,
                delete_location: false,
                view_location: false,
                create_activity: false,
                view_activity: false,
                edit_activity: action.payload.value,
                delete_activity: false
            });
    }
    else if(action.type === SET_MODAL_DELETE_ACTIVITY){
        // Set new list
        if(action.payload.fallback && !action.payload.value)
            return Object.assign({}, state, {
                create_location: false,
                edit_location: false,
                delete_location: false,
                view_location: false,
                create_activity: false,
                view_activity: true,
                edit_activity: false,
                delete_activity: false
            });
        else
            return Object.assign({}, state, {
                create_location: false,
                edit_location: false,
                delete_location: false,
                view_location: false,
                create_activity: false,
                view_activity: false,
                edit_activity: false,
                delete_activity: action.payload.value
            });
    }
    
    else if(action.type === SET_DRAWER){
        return Object.assign({}, state, {
            drawer: action.payload
        });
    }
    else if(action.type === SET_NAV_PAGE){
        return Object.assign({}, state, {
            nav_page: action.payload
        });
    }

    else if(action.type === SET_MODAL_SELECTED){
        return Object.assign({}, state, {
            selected: action.payload
        });
    }
    else if(action.type === SET_MODAL_SELECTED_ACTIVITY){
        return Object.assign({}, state, {
            selected_activity: action.payload
        });
    }

    return state;
};