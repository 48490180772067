import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button} from "@mui/material";
import ModalLayout from "../lib/ModalLayout";
import styled from 'styled-components'
import { ModalBody } from "../../styles/layout";
import { useSelector } from "react-redux";
import { Location } from "../../schemas/locations";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useState } from "react";
import store from "../../lib/store";
import { removeLocation } from "../../reducers/locations";

interface PropsInterface{
    open: boolean;
    onClose: (fallback: boolean) => void;
}

const DeleteLocation = ({open, onClose} : PropsInterface) => {
    const locations = useSelector((state : any) => state.locations)
    const modals = useSelector((state : any) => state.modals)

    const getSelected = () => {
        const index = locations.data.findIndex((loc : Location) => loc.id === modals.selected)

        if(index >= 0)
            return locations.data[index]
        return null;
    }

    // Snackbar
    const { enqueueSnackbar } = useSnackbar();

    // Handle delete
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const handleDelete = async () => {
        setButtonLoading(true);

        await fetch(`${process.env.REACT_APP_API_URL}/locations/${modals.selected}`,
            {
                method: "DELETE"
            }
        ).then((res) => res.json())
        .then((data) => {
            if(data.error){
                enqueueSnackbar(data.error, {
                    variant: "error"
                });
                return;
            }

            enqueueSnackbar(`Successfully deleted ${getSelected().name}`, {
                variant: "success"
            });

            store.dispatch(removeLocation(modals.selected))

            onClose(false)
        })
        .catch((err) => {
            enqueueSnackbar(`Failed to delete ${getSelected().name}`, {
                variant: "error"
            });
        })

        setButtonLoading(false);
    }

    return (
        <ModalLayout open={open} onClose={() => onClose(true)} title={getSelected() ? getSelected().name : ""} width="400px">
            <ModalBody>
                {
                    getSelected() && (
                        <>
                            <Text>
                                Are you sure you want to delete {getSelected().name}?
                            </Text>
                            <Box display="flex" gap={1} justifyContent="flex-end" alignItems="flex-end">
                                <Button 
                                    variant="outlined" 
                                    size="large" 
                                    color="primary"
                                    sx={{color: "white"}} 
                                    onClick={() => onClose(true)}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton 
                                    variant="contained" 
                                    size="large" 
                                    color={"error"}
                                    sx={{color: "white"}} 
                                    onClick={handleDelete}
                                    loading={buttonLoading}
                                >
                                    Delete
                                </LoadingButton>
                            </Box>
                        </>
                    )
                }
                
            </ModalBody>
        </ModalLayout>
    )
}

export default DeleteLocation;

const Text = styled.p`
    margin: 0 0 25px 0;
`