import { setAuthToken, setRefreshToken, setUserId } from "../reducers/auth";
import store from "./store";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const LogoutUser = () => {
    // Remove from cookies
    cookies.set("refreshToken", null);
    cookies.set("userId", null);

    // Remove from redux & localstorage
    store.dispatch(setAuthToken(null));
    store.dispatch(setUserId(null));
    store.dispatch(setRefreshToken(null));
}