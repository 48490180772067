import { faUtensils, faBagShopping, faPersonBiking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import styled from 'styled-components'
import { device } from "../../styles/breakpoints";

interface PropsInterface{
    dine_in?: boolean;
    takeout?: boolean;
    delivery?: boolean;
}

const Services = ({dine_in, takeout, delivery} : PropsInterface) => {

    return (
        <Holder>
            <Tooltip title="Dine-in">
                <Icon available={dine_in || false}>
                    <FontAwesomeIcon icon={faUtensils} />
                </Icon>
            </Tooltip>
            <Tooltip title="Takeout">
                <Icon available={takeout || false}>
                    <FontAwesomeIcon icon={faBagShopping} />
                </Icon>
            </Tooltip>
            <Tooltip title="Delivery">
                <Icon available={delivery || false}>
                    <FontAwesomeIcon icon={faPersonBiking} />
                </Icon>
            </Tooltip>
        </Holder>
    )
}

export default Services;

const Holder = styled.div`
    margin-top: 5px;
`

const Icon = styled.div<{available: boolean}>`
    text-align: center;
    display: inline-block;
    font-size: 14px;

    &:not(:first-of-type){
        margin-left: 10px;
    }

    width: 32px;
    height: 32px;
    line-height: 32px;
    color: white;

    border-radius: 100%;
    background: #d9534f;

    ${({available}) => available && `
        background: #5cb85c;
    `}

    @media ${device.xs}{
        font-size: 12px;
        line-height: 26px;
        width: 24px;
        height: 24px;
    }
`