import { ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import App from './App';
import theme from './styles/theme';
import { Provider } from "react-redux";
import store from './lib/store';
import {
  BrowserRouter as Router
} from "react-router-dom";
import './styles/index.css';
import fetchIntercept, { FetchInterceptorResponse } from 'fetch-intercept';
import { setAuthToken} from './reducers/auth';
import { LogoutUser } from './lib/auth';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Interceptors
fetchIntercept.register({
  request: function (url : string, config : any) {
      // Set header
      if(!config.headers)
        config.headers = {}
        
      // Check if access token set for storage
      if(store.getState().auth.token)
        config.headers["Authentication"] = `Bearer ${store.getState().auth.token ? store.getState().auth.token : ""}`

      config.headers["Content-type"] = "application/json"

      return [url, config];
  },

  requestError: function (error) {
      return Promise.reject(error);
  },

  response: function (response : FetchInterceptorResponse) {
    // Modify the reponse object
    return response;
  },

  responseError: async function (error) {
      if(error.status === 401 &&
          error.url === `${process.env.REACT_APP_API_URL}/auth/refresh`)
          return Promise.reject(error);
      
      if(error.status === 401 && !error._retry){
        error._retry = true;
        const refreshToken = localStorage.getItem("refreshToken");
        const userId = localStorage.getItem("userId");

        await fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
          method: "POST",
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            refreshToken: refreshToken,
            id: userId
          })
        })
        .then((res) => res.json())
        .then((data) => {
            if(data.token){
              store.dispatch(setAuthToken(data.token))

              return error;
            }
            else{
              // Logout user
              LogoutUser();
            }
          })
        .catch(err => {
          LogoutUser();
        })
      }

      // Handle an fetch error
      return Promise.reject(error);
  } 
});

fetchIntercept.register({
  response: function (response) {
    if (response.status === 401) throw response;
    return response;
  }
});



root.render(
  <Router>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </Router>
);