import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Switch, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { DefaultOptions, DefaultTypes, setFilterFoodTypes, setFilterOpenOnly, setFilterOptions, setFilterRating, setFilterSortBy, setFilterTypes } from "../../reducers/locations";
import { RatingLabel, RatingSlider } from "../../styles/layout";
import store from '../../lib/store'
import styled from 'styled-components'
import SearchableSelect from "./SearchableSelect";

const Filters = () => {
    const filters = useSelector((state : any) => state.locations.filters)
    const locations = useSelector((state : any) => state.locations)

    return (
        <>
            <Grid item xs={1}>
                <SearchableSelect 
                    label="Good for"
                    items={DefaultTypes} 
                    selectedItems={filters.types} 
                    onItemChange={(items) => store.dispatch(setFilterTypes(items))} 
                    checkAll 
                    disableSearch
                />
            </Grid>


            <Grid item xs={1}>
                <SearchableSelect 
                    label="Food"
                    items={locations.food_types} 
                    selectedItems={filters.food_types} 
                    onItemChange={(items) => store.dispatch(setFilterFoodTypes(items))} 
                    checkAll 
                    // placeholder="Search foodtype..."
                />
            </Grid>

            <Grid item xs={1}>
                <SearchableSelect 
                    label="Has option"
                    items={DefaultOptions} 
                    selectedItems={filters.options} 
                    onItemChange={(items) => store.dispatch(setFilterOptions(items))} 
                    checkAll 
                    disableSearch
                />
            </Grid>

            <Grid item xs={1}>
                <RatingLabel id="non-linear-slider" gutterBottom>
                    Rating
                </RatingLabel>
                <RatingSlider 
                    value={filters.min_rating}
                    onChange={(_, newValue) => store.dispatch(setFilterRating(Number(newValue)))}
                    max={5} 
                    min={0} 
                    valueLabelDisplay="auto" 
                    step={0.1} 
                />
            </Grid>

            <Grid item xs={1}>
                <FormControl sx={{width: "100%"}}>
                    <InputLabel shrink id="sort-select-label">Sort by</InputLabel>
                    <Select
                        size="small"
                        labelId="sort-select-label"
                        id="sort-select"
                        input={<OutlinedInput label="Sort by" />}
                        value={filters.sort_by}
                        onChange={(e) => store.dispatch(setFilterSortBy(e.target.value))}
                        >
                        <MenuItem value={""}>Unfiltered</MenuItem>
                        <MenuItem value={"Rating"}>Rating</MenuItem>
                        <MenuItem value={"Distance"}>Distance</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            {
                /*
                <Grid item xs={1}>
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Open/Closed</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                            control={
                                <Switch value={filters.open_only} onChange={(_, newValue) => store.dispatch(setFilterOpenOnly(newValue))} />
                            }
                            label={(filters.open_only) ? "Open Only" : "Any"}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                */
            }

            <Grid item xs={1}>
                <SwitchHolder>
                    <BigSwitch 
                        value={filters.open_only} 
                        onChange={(_, newValue) => store.dispatch(setFilterOpenOnly(newValue))} />
                        <SwitchLabel>
                            {
                                filters.open_only ? "Open" : "Any"
                            }
                        </SwitchLabel>
                </SwitchHolder>
            </Grid>
        </>
    )
}

export default Filters;

const BigSwitch = styled(Switch)`
    width: 100%;
    padding: 0;


    & .MuiSwitch-input{
        width: 1000%;
    }
`

const SwitchLabel = styled.span`
    text-align: center;
    position: absolute;
    font-size: 14px;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: white;
    pointer-events: none;
    user-select: none;
`

const SwitchHolder = styled.div`
    position: relative;
`