import styled from 'styled-components'
import moment from 'moment'

interface PropsInterface{
    open: Date;
    closed: Date;
}

const OpeningHours = ({open, closed} : PropsInterface) => {

    // Check if is open now
    const isOpenNow = () => {
        const dateNow = new Date();

        return dateNow >= open && dateNow <= closed;
    }

    // Check if date is today
    const isToday = (date : Date) => {
        const today = new Date()
        return date.getDate() == today.getDate() &&
            date.getMonth() == today.getMonth() &&
            date.getFullYear() == today.getFullYear()
    }

    return (
        <Holder>
            {
                isOpenNow()
                ? (<>
                    <Status open>Open</Status> Closes at {moment(closed).format("HH:mm")}
                </>)
                : (<>
                    <Status>Closed</Status> Opens at {moment(open).format("HH:mm")} { !isToday(open) && moment(open).format('dddd').substr(0, 3)}
                </>)
            }
        </Holder>
    )
}

export default OpeningHours;

const Holder = styled.div`
    font-size: 12px;
`; 

const Status = styled.div<{open?: boolean}>`
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    color: white;
    background: #d9534f;

    ${({open}) => open && `
        background: #5cb85c;
    `}
`;